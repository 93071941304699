

//(function () {
  let wishlistData=[];
  let dislikesData=[]
  let orderTypePickup=$('#orderTypePickup').val();
  let orderTypeDelivery=$('#orderTypeDelivery').val();
  let orderTypeOrderAhead=$('#orderTypeOrderAhead').val();
  let customerRevenueSharesValue;
  /* tbdeleted
    if (sessionStorage) {
    if(sessionStorage.getItem("orderTypePickup")) orderTypePickup  = sessionStorage.getItem("orderTypePickup").trim();
    if(sessionStorage.getItem("orderTypeDelivery")) orderTypeDelivery  = sessionStorage.getItem("orderTypeDelivery").trim();
    if(sessionStorage.getItem("orderTypeOrderAhead")) orderTypeOrderAhead  = sessionStorage.getItem("orderTypeOrderAhead").trim();
    //when page refreshed, value is being read from session, but requestbody may be different, so set the ui from here again
  } */
      let url=window.location.href;
      let pathName = window.location.pathname;
      let newurl=url.split('/').pop().split('.').shift();
      if(orderTypePickup == "off") {

        if(newurl!='homepage'){
          $("#home-pick-up-ordertype .fa-check").removeAttr('style');
          $('#home-pick-up-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
       
          $("#home-toprated .fa-check").removeAttr('style');
          $('#home-toprated .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
         
        }
        else if(newurl=='homepage'){
          $("#home-pick-up-ordertype .fa-check").removeAttr('style');
          $('#home-pick-up-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          
          $("#home-toprated .fa-check").removeAttr('style');
          $('#home-toprated .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
        }
        $("input[name=orderTypePickup]").val("off");
        $("input[name=orderTypePickup]").prop('checked', false);
        $('#home-pick-up-ordertype span').css({'color': '#707070'});
        $('#home-toprated span').css({'color': '#707070'});

      }
      else {
        if(newurl!='homepage'){
          $("#home-pick-up-ordertype .fa-check").removeAttr('style');
          $('#home-pick-up-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});

          $("#home-toprated .fa-check").removeAttr('style');
          $('#home-toprated .fa-check').css({'background-color': '#02843d', 'color': 'white'});

        }else if(newurl=='homepage'){
          $("#home-pick-up-ordertype .fa-check").removeAttr('style');
          $('#home-pick-up-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});
          
          $("#home-toprated .fa-check").removeAttr('style');
          $('#home-toprated .fa-check').css({'background-color': '#02843d', 'color': 'white'});
        

        }

        // $("input[name=orderTypePickup]").val("on");
        // $("input[name=orderTypePickup]").prop('checked', true);
        $('#home-toprated').css({'color': '#02843d'});
        $('#home-pick-up-ordertype span').css({'color': '#02843d'});
      }
      if(orderTypeDelivery == "off") {
        if(newurl!='homepage'){

          $("#home-delivery-ordertype .fa-check").removeAttr('style');
          $('#home-delivery-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
        
          $("#home-closet .fa-check").removeAttr('style');
          $('#home-closet .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
        
        }
        else if(newurl=='homepage'){
          $("#home-delivery-ordertype .fa-check").removeAttr('style');
          $('#home-delivery-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          
          $("#home-closet .fa-check").removeAttr('style');
          $('#home-closet .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
        
        }
        $("input[name=orderTypeDelivery]").val("off");
        $("input[name=orderTypeDelivery]").prop('checked', false);
        $('#home-delivery-ordertype span').css({'color': '#707070'});
        $('#home-closet span').css({'color': '#707070'});
      }
      else {
        if(newurl!='homepage'){
          $("#home-delivery-ordertype .fa-check").removeAttr('style');
          $('#home-delivery-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});
          $("#home-closet .fa-check").removeAttr('style');
          $('#home-closet .fa-check').css({'background-color': '#02843d', 'color': 'white'});
        

        }else if(newurl=='homepage'){
          $("#home-delivery-ordertype .fa-check").removeAttr('style');
          $('#home-delivery-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});
          
          $("#home-closet .fa-check").removeAttr('style');
          $('#home-closet .fa-check').css({'background-color': '#02843d', 'color': 'white'});
        
        }

        // $("input[name=orderTypeDelivery]").val("on");
        // $("input[name=orderTypeDelivery]").prop('checked', true);

        $('#home-closet span').css({'color': '#02843d'});
        $('#home-delivery-ordertype span').css({'color': '#02843d'});
        $('#home-closet span').css({'color': '#02843d'});
      }
      if(orderTypeOrderAhead == "off") {

        if(newurl!='homepage'){
          $("#home-orderahead-ordertype .fa-check").removeAttr('style');
          $('#home-orderahead-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
        
          $("#home-mostfreefood .fa-check").removeAttr('style');
          $('#home-mostfreefood .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
        
        }
        else if(newurl=='homepage'){
          $("#home-orderahead-ordertype .fa-check").removeAttr('style');
          $('#home-orderahead-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          
          $("#home-mostfreefood .fa-check").removeAttr('style');
          $('#home-mostfreefood .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
        
        }
        $("input[name=orderTypeOrderAhead]").val("off");
        $("input[name=orderTypeOrderAhead]").prop('checked', false);

        $('#home-mostfreefood span').css({'color': '#707070'});
        $('#home-orderahead-ordertype span').css({'color': '#707070'});
      }

      else {
        if(newurl!='homepage'){
          $("#home-orderahead-ordertype .fa-check").removeAttr('style');
          $('#home-orderahead-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});

          $("#home-mostfreefood .fa-check").removeAttr('style');
          $('#home-mostfreefood .fa-check').css({'background-color': '#02843d', 'color': 'white'});

        
        }else if(newurl=='homepage'){
          $("#home-orderahead-ordertype .fa-check").removeAttr('style');
          $('#home-orderahead-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});
          $("#home-mostfreefood .fa-check").removeAttr('style');
          $('#home-mostfreefood .fa-check').css({'background-color': '#02843d', 'color': 'white'});
         
        }

        // $("input[name=orderTypeOrderAhead]").val("on");
        // $("input[name=orderTypeOrderAhead]").prop('checked', true);
        $("#home-mostfreefood").css({'color': '#02843d'});
        $('#home-orderahead-ordertype span').css({'color': '#02843d'});
      }

      
      
   
  $(document).ready(function(){
    
    let userReferrerId = $("#user-referrerId").val();
    if($(window).width() < 424){
      $('#home-qrcode').qrcode({
        width: 70,
        height: 70,
        text: window.location.origin +'/s/'+userReferrerId,
        image: '/images/pr-icon.png'
      });
    }
    const allRanges = document.querySelectorAll(".range-control");
    allRanges.forEach(wrap => {
      const range = wrap.querySelector(".home-rangevalue");
      const bubble = wrap.querySelector(".home-range-bubble");

      range.addEventListener("input", () => {
        setBubble(range, bubble, false);
      });
      setBubble(range, bubble, true);
    });
    function setBubble(range, bubble, flag = false) {
      let realVal = range.value;
      let val = range.value;
      const min = range.min ? range.min : 0;
      const max = range.max ? range.max : 100;
      let newVal = Number(((val - min) * 100) / (max - min));
      const inputId = $(range).attr('id');
      const parentElem = $(range).parent();
      if(parentElem){
        parentElem.attr('data-value', val);
      }

        let units = '';
        if(inputId=="home-distance-slider"){
          if (Number(val) < 25) {
              val = setRadius(val, 25, 0.1, 0.5)
          } else if (Number(val) < 50) {
              val = setRadius(val - 25, 25, 0.5, 5)
          } else {
              val = setRadius(val - 50, 50, 5, 100)
          }
          $("#home-distance").val(val);
        units = 'm';
        val = val+''+units;
      }else if(inputId=="home-delivery-time"){
        units = 'mi';
        val = val+''+units;
      }else if(inputId=="home-delivery-fee"){
        units = '$';
        val = units+''+val;
      }
      if(flag === true && inputId=="home-distance-slider"){
        let searchRadius = realVal || 0;
        if (Number(realVal) < 0.5) {
            searchRadius = getRadiusVal(realVal, 25, 0.1, 0.5)
            searchRadius = Number(searchRadius) + 0.1;
        } else if (Number(realVal) < 5) {
            searchRadius = getRadiusVal(realVal, 25, 0.5, 5)
            searchRadius = Number(searchRadius) + 25;
        } else {
            searchRadius = getRadiusVal(realVal, 50, 5, 100)
            searchRadius = Number(searchRadius) + 50;
        }
        // bubble.innerHTML = realVal+''+'m';
        realVal = Number(((realVal - min) * 100) / (max - min));
        $("#home-distance-slider").val(searchRadius);
        // move value with range bubble
        bubble.style.left = `calc(${searchRadius}% + (${8 - searchRadius * 0.15}px))`;
      }else{
        bubble.innerHTML = val;
        bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
      }
    }
    let priceVal = $('#price_search_header').val();
    if(priceVal){
      let arr = priceVal.split(',');
      if(arr.length > 1){
      // after set the slider for showing the selected value
      setTimeout(function() {
        if(arr[0]==100){
          $(" #priceRange_header .min-slider-handle").html("<p  style='position: absolute;top: 20px;width: 60px;' class='low'>$" +arr[0] + '+</p>');
          $("#priceRange .min-slider-handle").html("<p  style='position: absolute;top: 20px;width: 60px;' class='low'>$" +arr[0] + '+</p>');
        }
        else{
          $("#priceRange_header .min-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$" +arr[0] + '</p>');
          $("#priceRange .min-slider-handle").html("<p  style='position: absolute;top: 20px;width: 60px;' class='low'>$" +arr[0] + '</p>');

        }
        if(arr[1]==100){
          $("#priceRange_header .max-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$" +arr[1] + '+</p>');
          $("#priceRange .max-slider-handle").html("<p  style='position: absolute;top: 20px;width: 60px;' class='low'>$" +arr[1] + '</p>');
        }
        else{
          $("#priceRange_header .max-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$" +arr[1] + '</p>');
          $("#priceRange .max-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$" +arr[1] + '</p>');
        }
      },500);
      }
    }
    let maxFlavour = 80;
    flavorMatchfun(maxFlavour);

  });

  window.addUserPrefs = function (dietPrefs, divid="dietaryPreferencesHeader"){
      //pre-select food preferences
        if (dietPrefs) {
            let selectedvalue=[];
            for (let i = 0; i < dietPrefs.length; i++)
            {
                if ($('#'+divid).find("option[value='" + dietPrefs[i]+ "']").length) {
                     selectedvalue.push(dietPrefs[i]);
                } else {
                    // Create a DOM Option and pre-select by default
                    let newOption = new Option(dietPrefs[i], dietPrefs[i], true, true);
                    // Append it to the select
                    $('#'+divid).append(newOption).trigger('change');
                     selectedvalue.push(dietPrefs[i]);
                }
                $('#'+divid).val(selectedvalue).trigger('change');
            }
        } else {
            console.log('User should fill in her preferences');
        }

    };
    $('#show-food-not-meet-header').click(function (event) {
      if (this.checked) {
          let selectedval = $('#dietaryPreferencesHeader').val();
          let getuserdata = userdietnames;
          let concatdata = selectedval.concat(getuserdata);
          let uniquedata = $.unique(concatdata);
          let result = Array.from(new Set(uniquedata));
          addUserPrefs(result,"dietaryPreferencesHeader");
      }
      else {
          let selectedval = $('#dietaryPreferencesHeader').select2("val");
          if (selectedval.length > 0 && userdietnames.length > 0) {
              let diff = getMatch(userdietnames, selectedval);
              if (diff && diff.length > 0) {
                  addUserPrefs(diff,"dietaryPreferencesHeader");
              } else {
                  $('#dietaryPreferencesHeader').val(null).trigger('change')
              }
          }
      }
    });
  //}
  function flavorMatchfun(maxFlavour){
    let htmlvalue='';
      if(maxFlavour){
       htmlvalue=' <input class="input-filter slider-infl slider" id="maxrestFlavour" name="maxrestFlavour" type="range" min="0" max="100" value="'+maxFlavour+'" ><span id="maxflvrmatchidrest">'+maxFlavour+'</span>';
        $(".maxflvrmatch").html(htmlvalue);
        setTimeout(()=>{
          $('#maxrestFlavour').on('input',function(e){
            let flavorValue = Number($(this).val() || 0);
            $('#maxrestFlavour').val(flavorValue);
            $('#maxflvrmatchidrest').text(flavorValue);
            maxFlavour = flavorValue;

          });
        }, 100)
      }
  }
  let primaryType = null; // this depends on the tab clicked, can be menuitem or restaurant
  if (window.location.href.indexOf("basicsearch") > -1) {
   $(`#intro-container`).css('display', 'none');
  }

  // $("#headersearch-radius-value-text").text($("#header_search_radius_input").val());

  const $RADIUS_INPUT = $('.search-radius-input, .search-radius-private_room');
  const $RADIUS_VALUE = $("input[name='radius']"); //$('#radius');
  // const $RADIUS_PRIVATE_ROOM = $('.search-radius-private_room');
  const $RADIUS_PRIVATE_VALUE = $('#radius_private_room');
  const $RADIUS_VALUE_TEXT = $('.radius-value-text');
  const $STARTTIMEPICKER = $('#start_time');
  const $ENDTIMEPICKER = $('#end_time');
  const $LATITUDE = $('#lat');
  const $LONGITUDE = $('#lng');
  const $SSWITCH = $('.checkbox');

  const venueprivateroomsContainer = $('.nearby-restaurants-privaterooms-container');
    let googleLocation = false;
    $.ajax({
      url: '/platerateadmin/getsettings',
      method: 'GET',
      dataType: 'json',
    }).then(function (res) {
      if(res && res.data && res.data.minOrderAmountForCustomerReferralCredit){
        $('#about-referral-credit').text(res.data.minOrderAmountForCustomerReferralCredit);
        customerRevenueSharesValue = res.data.customerRevenueShares;
      }
      googleLocation=res.data.googleLocation;
      if(googleLocation==true){
        function initialize() {
          let input = document.getElementById('locationinputheader');
          new google.maps.places.Autocomplete(input);
        }
      //tbd mangita      google.maps.event.addDomListener(window, 'load', initialize);
       }
      let htmlIconLink = ``;
      if(res.data.listLink){
        htmlIconLink = `
        <a class="btn btn-xs facebook-share-button"
            data-text="Check this out on PlateRate ! #platerate"
            href="${res.data.listLink.linkfacebook}"
            target="_blank">
            <i class="fab fa-facebook-f"></i>
          </a>

          <a class="btn btn-xs twitter-share"
            data-text="Check this out on PlateRate ! #platerate"
            href="${res.data.listLink.linkTwitter}" target="_blank"><i
              class="fab fa-twitter"></i></a>

          <a class="btn btn-xs linkedin-share-button"
            data-text="Check this out on PlateRate ! #platerate"
            href="${res.data.listLink.linkLinkedin }"
            target="_blank"><i class="fab fa-linkedin-in"></i></a>

          <a class="btn btn-xs linkedin-share-button"
            data-text="Check this out on PlateRate ! #platerate"
            href="${res.data.listLink.linkPinterst}" target="_blank"><i
              class="fab fa-pinterest-p" style="font-size:22px"></i></a>
          
          <a class="btn btn-xs instagram-share-button"
            data-text="Check this out on PlateRate ! #platerate"
            href="${res.data.listLink.linkInstagram }"
            target="_blank"><i class="fab fa-instagram"></i></a>
        `
      }
      $('#foot-social-links').html(htmlIconLink);
      $('#foot-social-links-mobile').html(htmlIconLink);
    });

    $('#icon-calendar-open').on('click',function(){
      $('#open_hours_datetimepicker').trigger('focus');
    })
    $('#open_hours_datetimepicker').datetimepicker({
        showClose: true,
        icons: {
          close: 'OK'
        }
    }).on('dp.change', function(e){
    });
    $("#open_hours_datetimepicker").on("dp.show", function(e) {
      $('.OK').html("OK").css("background-color","darkgrey");
    });
    //advanced search
    $('#fa-calendar-advanced').on('click',function(){
      $('#open_hours_datetimepicker_advance').trigger('focus');
    })
    $('#open_hours_datetimepicker_advance').datetimepicker({
        showClose: true,
        icons: {
          close: 'OK'
        }
    }).on('dp.change', function(e){
    });
    $("#open_hours_datetimepicker_advance").on("dp.show", function(e) {
      $('.OK').html("OK").css("background-color","darkgrey");
    });


	$('#nointeractions_sincedate_datetimepicker').datetimepicker({
       format:'L',
       defaultDate: moment().subtract(3, 'months')
  });
  //set the selected category ids and its subcategory ids; likewise for names
  let selectedmenutypes = $("#menuTypePreferences").val();
  if(selectedmenutypes && selectedmenutypes.length>0){
    setAllSelectedCategories();
  }

  $RADIUS_INPUT.on('input', function() {
      //$RADIUS_INPUT.attr('max', 100);
      let radius = Number($(this).val()||0);
      if (Number(radius) < 25) {
          radius = setRadius(radius, 25, 0.1, 0.5)
      } else if (Number(radius) < 50) {
          radius = setRadius(radius - 25, 25, 0.5, 5)
      } else {
          radius = setRadius(radius - 50, 50, 5, 100)
      }
      $RADIUS_INPUT.val($(this).val());
      $RADIUS_VALUE_TEXT.text(radius);
      $RADIUS_VALUE.val(radius);
      $RADIUS_PRIVATE_VALUE.val(radius)
  });

  $RADIUS_VALUE_TEXT.keyup(function(){
    let radius = Number($(this).val()||0);
    setRadiusInputVal(radius);
  });
  function setRadiusInputVal(radius){
      if (Number(radius) < 0.5) {
          radius = getRadiusVal(radius, 25, 0.1, 0.5)
          radius = radius + 0.1;
      } else if (Number(radius) < 5) {
          radius = getRadiusVal(radius, 25, 0.5, 5)
          radius = radius + 25;
      } else {
          radius = getRadiusVal(radius, 50, 5, 100)
          radius = radius + 50;
       }
      $RADIUS_INPUT.val(radius);
  }

    const getRadiusVal = function (radius, prossesMax, min, max) {
        let length = max - min;
        let rate = (radius - min) / length;
        let prosses = rate * prossesMax;
        return prosses.toFixed(1);
    }
    // setRadiusInputVal($("#headerradius-val").val());
    const setRadius = function (prosses, prossesMax, min, max) {
        let length = max - min;
        let rate = prosses / prossesMax
        let radius = min + (rate * length);
        return radius.toFixed(1);
    }

    $STARTTIMEPICKER.datetimepicker({
        format: 'LT',
        stepping: 15
    });
    $STARTTIMEPICKER.on('dp.change', function(e) {
    /*    console.log('old date:', e.oldDate); //returns Moment object of old date
        console.log('selected date:', e.date); //returns Moment object of selected date
        console.log('formatted date:', e.date.format('MMM DD, YYYY')); //you can do normal operation that moment allows
    */
    });

    $ENDTIMEPICKER.datetimepicker({
        format: 'LT',
        stepping: 15
    });
     $ENDTIMEPICKER.on('dp.change', function(e) {
      /*    console.log('old date:', e.oldDate); //returns Moment object of old date
          console.log('selected date:', e.date); //returns Moment object of selected date
          console.log('formatted date:', e.date.format('MMM DD, YYYY')); //you can do normal operation that moment allows
     */
    });

    $(".datepicker").datetimepicker({
    });
    $('#icon-calendar').click(function(){
        $('#book_date').trigger('focus');
    })

    // $('#icon-calendar').click(function(){
        // $('#book_date').trigger('focus');
    // });

    $SSWITCH.Sswitch({});

    $('.private-rooms').change(function(){
        let room_search = {};
        room_search.num_people = $('input[name=number_people]').val();
        room_search.date = $('#book_date').val();
        room_search.seat_stand = $('input[name=fits]').prop('checked');
        room_search.start_time = $('#start_time').val();
        room_search.end_time = $('#end_time').val();
        room_search.max_person = $('input[name=max_person]').val();
        room_search.max_budget = $('input[name=max_budget]').val();

        sessionStorage.room_search = JSON.stringify(room_search);

    })

    $('div .s-switch').on('click',function(){
        $('.private-rooms').trigger('change');
    })

    /* commenting this, we dont have private room search now
    $(document).ready(function(){
        let room_search;
        try {
            room_search = JSON.parse(sessionStorage.room_search);
            $('input[name=number_people]').val(room_search.num_people);
            $('#book_date').val(room_search.date);
            if(room_search.seat_stand){
                $('input[name=fits]').prop('checked',true);
                $('div .s-switch').trigger('click');
            }
            $('#start_time').val(room_search.start_time);
            $('#end_time').val(room_search.end_time);
            $('input[name=max_person]').val(room_search.max_person);
            $('input[name=max_budget]').val(room_search.max_budget);
        } catch (err) {
            return
        }
    })
*/
    //for advance search
    $("#advance-locationinputsearch").on('change', function(e) {
        $("#advance-lat").val("");
        $("#advance-lng").val("");
    });

    $("#headersearch-locationinputsearch").on('change', function(e) {
        $("#headersearch-lat").val("");
        $("#headersearch-lng").val("");
    });
    $('.reset-btn').on('click', function(e) {
        $(".search-input").val("").end(); //the input fields should have this classname
    });


//    $('.search-my-restaurant').on('click', function(){
    $(document).on("click",".search-my-restaurant",function(e) {
         //event.preventDefault();
        var counter=0;
        if (e.originalEvent === undefined) {
          // code triggered click
          counter=0
        } else {
          // real click from user
          counter=1;
        }
        // alert("hjhjh");
         //console.log("param1====",e.data.param1)
        $(`#serach-loader`).css('display', 'block');
        $('#searchbutton').attr("disabled", true);
        let searchinput=$("input[name=searchinput]").val();
        let locationinput=$("input[name=locationinput]").val();
        let range=$("input[name=range]").val();
        let radius=50;
        let lat=$("#lat").val();
        let lng=$("#lng").val();
        let googleFlag=$("input[name=googleFlag]").val();
        let homepage = false;
        $.ajax({
            url: "/searchMyRestaurant",
            data: {searchinput:searchinput,locationinput:locationinput,range: range,radius:radius,lat:lat,lng:lng,googleFlag:googleFlag,homepage:homepage,counter:counter},
            type: 'post',
            error: function(e){
              $(`#serach-loader`).css('display', 'none');
              $('#searchbutton').attr("disabled", false);
            },
            success: function(results){
              $(`#serach-loader`).css('display', 'none');
              $('#searchbutton').attr("disabled", false);
              if(results){
                 $('#homepagesearch').html('');
          	     $('#search-menuItemsDiv').html('');
                  resultsMenu = results.resultsMenuItems;
                  resultsVenues = results.resultsVenues;
                  mapDataMenuItems = results.mapDataMenuItems;
                  mapDataVenues = results.mapDataVenues;
                  searchStringRestaurant = searchinput;
                  searchStringMenuItem =  searchinput;
                  venuesDetails= results.venuesDetails;
                  searchResults(primaryType,1); //this populates and displays
                  //uncheck the order types
                  console.log("still checking the order types");
                  // $("input[name=orderTypePickup]").val("on");
                  // $("input[name=orderTypePickup]").prop('checked', false);
                  // $("input[name=orderTypeDelivery]").val("off");
                  // $("input[name=orderTypeDelivery]").prop('checked', false);
                  // $("input[name=orderTypeOrderAhead]").val("off");
                  // $("input[name=orderTypeOrderAhead]").prop('checked', false);
              }
              //show the add rest button
              // if(counter==0){
              //   $('#missing-restaurant-div2').hide();
              //   $('#nosearchresult').show();
              //   $('#nosearchresult #iorder').text("Don't see your business?");
              //   $('#ratenot').hide();
              //   $("#textchange").text("Search all  businesses");
              //   $('#missing-restaurant-div1').show();
              // }else{
                 $('#missing-restaurant-div2').show();
                  $('#nosearchresult').hide();
                  $('#missing-restaurant-div1').hide();
              //}
              
            }
        })
    })

    /* type = search if search clicked, = localtion if location changed
    if location changed, then load homepage for new location if user on the homepage, else do search
    */
    function commonajaxcallforhome(type="search"){
      $("#mobileslectboxcurrentlocation").hide();
      $("#slectboxcurrentlocation").hide();
     /* load homepage or searh depending on its origin */
      const isHomePage = window.location.pathname == '/homepage' ;
      let locationinput = $("#hide-locationinput").html() || $("#homepage-location-input-search").val();
      // let locationinput=$("#homepage-location-input-search").val();
      console.log("in commonajaxcallforhome...locationinput=",locationinput, type);
       if(locationinput && (locationinput=='' || locationinput.trim() =='Enter Your Location')){ 
        var profileadress=$(".profileaddress").val();
        localforage.setItem('locationinput', profileadress);
        localforage.setItem('latitude', '') ;
        localforage.setItem('longitude', '') ;
        $("#lat").val('');
        $("#lng").val('');  
        $("#head-location-input-search").val("") ;       
      }
      if (isHomePage && type === "location") {
        let primaryType = $("#primaryType").val();
     	  showHomepage(
          primaryType,
          orderTypeDelivery,
          orderTypePickup,
          orderTypeOrderAhead,
          true
        );
      } else {
        setAllSelectedCategories();
        let primaryType = $("#primaryType").val();
        
        if(typeof primaryType==='undefined'){
          primaryType='restaurant';
        }
        console.log("primaryType===33=-===",primaryType);
        const formSearch = $('form#head-form-search-basic');
        let str = formSearch.serialize();
        let inputstr='';
        if(str) inputstr+="&"+str;

        let filter_menu_type_prefs=localStorage.getItem('filter_menu_type_prefs');
        if(filter_menu_type_prefs!=null){
          inputstr+='&filter_menu_type_prefs='+filter_menu_type_prefs;
        }
        let url = '/basicsearch/submit?newurl=true&primaryType='+primaryType+inputstr;
        console.log("commonajaxcallforhome url=====",url)
        location.replace(url);
      }

    }
//header search click
  $('.findclass,.homearrow').on('click', function(event) {
      event.preventDefault();
      commonajaxcallforhome("search");
  });
  /*$(document).on("blur","#search",function(e) {
    event.preventDefault();
    commonajaxcallforhome("search");
  });*/
  $(document).on('click', '#head-search-button', function (event) {
  
      event.preventDefault();
      commonajaxcallforhome("search");
  });
  $('#head-search-button-1').on('click', function(event) {
    event.preventDefault();
    console.log("i am here for testing")
    commonajaxcallforhome("search");
});
  $('#search, #searchinputcenter').keyup(function(e){
    if (e.keyCode === 13) {
	setAllSelectedCategories();
	const formSearch = $('form#head-form-search-basic');
	let primaryType ='menuitem'
        if(document.getElementById("primaryType")){
         primaryType= document.getElementById("primaryType").value ;
        } 
        let str = formSearch.serialize();
        console.log("url from search return...",str);
        location.assign('/basicsearch/submit?newurl=true&primaryType='+primaryType+'&'+str);
     }
  });
  /*$('#searchinputcenter').blur(function(e){
     const formSearch = $('form#head-form-search-basic');
      if($("#searchinputcenter").val()!=''){
        let primaryType = document.getElementById("primaryType").value ;
        let str = formSearch.serialize();
        location.assign('/basicsearch/submit?newurl=true&primaryType='+primaryType+'&'+str);
      }
  })*/
  $('#apply_search_filter_header').on('click', function(e) {
      event.preventDefault();
      //Reset auto detected lat and long if user typed custom address
      setAllSelectedCategories();
      const formSearch = $('form#form-search-filter');
      let str = formSearch.serialize();
      $(`#filterserach-loader`).css('display', 'block');
      let primaryType ='menuitem'
      if(document.getElementById("primaryType")){
       primaryType= document.getElementById("primaryType").value ;
      }
      localStorage.setItem('radiustext',$("#headersearch-radius-value-text").text());
      localStorage.setItem('radiusvaltext',$("#header_search_radius_input").val());
       location.assign('/basicsearch/submit?newurl=true&primaryType='+primaryType+'&'+str);
  });
  let eventname='';
  if($(window).width() < 768){
    eventname="change";
  }else{
     eventname="click";
  }
  $(document).on(eventname," #priceRange,#sidefilter-delivery-time,#sidefilter-deliveryfee,#sidefilter-distance",function(e) {
    $(`#serach-loader`).css('display', 'block');
    setTimeout(function() {
     $(`#search_filter_divmobile`).css('display', 'none');
    },1000);
    $('html, body').animate({ scrollTop: $(`#form-search-basic`).offset().top  }, 800);

    startSearch("search_filter_div");
  });
  $(document).on("click ",".item-star-rating,  #happyhour,#filter_locals_rating",function(e) {
     $(`#serach-loader`).css('display', 'block');
     $('html, body').animate({ scrollTop: $(`#form-search-basic`).offset().top  }, 800);

    startSearch("search_filter_div");
  });
  let cuisineSearchCounter = 0; //this will get incremented after every cuisinesearch or ajax call for cuisine;
  //so for top cuisineclick, it will be 0 and hence not do search; for any cuisine selected from left after that, this will be >=1 and hence do search
  //if no top cuisineclick, then there is no clickCheck and hence will do search
  $(document).on("change","#menuTypePreferences",function(e) {
     e.preventDefault(); //important to not have the search trigger automatically
    let clickCheck = $("#cuisinesearch").val(); //this is set if cuisine list on homepage top is clicked
    if(!clickCheck || cuisineSearchCounter>=1 ){
      $(`#serach-loader`).css('display', 'block');
      if(isHomePage || window.location.href.indexOf("basicsearch") > -1){
       $('html, body').animate({ scrollTop: $(`#form-search-basic`).offset().top }, 800);
        document.getElementById("primaryType").value ='restaurant';
        var searchType = sessionStorage.getItem("primaryType")
        if(searchType){
          startSearch("search_filter_div");
        }
      }
    } else {
       cuisineSearchCounter++;
    }
  });
  $(' #menuTypePreferences').keyup(function(e){
    if (e.keyCode === 13) {
      if(isHomePage || window.location.href.indexOf("basicsearch") > -1){
        $('html, body').animate({ scrollTop: $(`#form-search-basic`).offset().top }, 800);
        startSearch("search_filter_div");
      }
    }
  });
  let cuisiondata='';
  $(document).on("click",".menuTypePreferences ",function(e) {
     event.preventDefault();
     let input = $(this).text().trim();
     if(input && input.startsWith("Vegetarian")){
       input = "Vegetarian / Vegan" /* to match with the foursquare cuisines */
     }
     $("#cuisinedata").val(input)
      const formSearch = $('form#form-search-center');
      let locationinput = $("#head-location-input-search").val();
      let radius = $("#header_search_radius_input").val();
      let str = formSearch.serialize();
  //send cuisinesearch in reqbody so that some other prams like diet pref and order types will not be appllied for cuisine
//this may clash tbdeleted vandana      if (sessionStorage) sessionStorage.setItem("primaryType","restaurant");
      location.assign('/basicsearch/submit?newurl=true&cuisinesearch=true&locationinput='+locationinput+'&radius='+radius+'&'+str);
  });
  $(document).on("change","#cuisoninput",function(e) {
     setAllSelectedCategories();
    const formSearch = $('form#form-search-center');
    let locationinput = $("#head-location-input-search").val();
    let primaryType = 'restaurant' ;
    console.log("primaryType===1111=",primaryType)
    let str = formSearch.serialize();
//this may clash tbdeleted vandana     if (sessionStorage) sessionStorage.setItem("primaryType","restaurant");
    location.assign('/basicsearch/submit?newurl=true&cuisinesearch=true&locationinput='+locationinput+'&primaryType='+primaryType+'&'+str);

  });
//on sorting change, set the checkboxes values and resubmit the form
//bring back the preselected tab as primarytype
//these checkbox ids are defined in search.filters.ejs
  let sortorders =false;
  $(document).on("change","#search_results_div .sortorders,#venueItemsDiv .sortorders",function(e) {
  let selectedsort = $(this).attr("data-value");
  console.log("sorting by...", selectedsort);
  sortorders=true;


      if(selectedsort=="closest"){
         if($(this).prop("checked") == true){
          $("input[name=checkboxClosest]").val("on");
        }
        else{
           $("input[name=checkboxClosest]").val("off");
        }
      }
      else if(selectedsort=="lowCost"){
        if($(this).prop("checked") == true){

          $("input[name=checkboxLowCost]").val("on");
        }
        else{
           $("input[name=checkboxLowCost]").val("off");
        }
      }
      else if(selectedsort=="rated"){
        if($(this).prop("checked") == true){
          $("input[name=checkboxRated]").val("on");
        }
        else{
          $("input[name=checkboxRated]").val("off");
        }
      }
      else if(selectedsort=="discount"){
        if($(this).prop("checked") == true){
          $("input[name=checkboxDiscount]").val("on");
        }
        else{
          $("input[name=checkboxDiscount]").val("off");
        }
      }
      else if(selectedsort=="flavor"){
        if($(this).prop("checked") == true){
          $("input[name=checkboxFlavor]").val("on");
        }
        else{
          $("input[name=checkboxFlavor]").val("off");
        }
      }
      else if(selectedsort=="value"){
        if($(this).prop("checked") == true){
          $("input[name=checkboxValue]").val("on");
        }
        else{
          $("input[name=checkboxValue]").val("off");
        }
      }
      else if(selectedsort=="minimumOrder"){
        if($(this).prop("checked") == true){
          $("input[name=checkboxMinimumOrder]").val("on");
        }
        else{
          $("input[name=checkboxMinimumOrder]").val("off");
        }
      }
      else if(selectedsort=="deliveryFee"){
        if($(this).prop("checked") == true){
          $("input[name=checkboxDeliveryFee]").val("on");
        }
        else{
          $("input[name=checkboxDeliveryFee]").val("off");
        }
      }
      $(`#serach-loader`).css('display', 'block');
      startSearch("search_filter_div");

  });
 function startSearch(parentdivid){
   if($.xhrPool && typeof $.xhrPool !== undefined ) $.xhrPool.abortAll();
    $(`#serach-loader`).css('display', 'block');
    $(document).scrollTop( $("#serach-loader").offset().top-50 );
    let filter_meet_dietary_prefs=$("#"+parentdivid+" #filter_meet_dietary_prefs").val();
    let filter_menu_type_prefs=null;
    filter_menu_type_prefs=$("#"+parentdivid+" #menuTypePreferences").val();
    let newfilter_menu_type_prefs = null;
    if( filter_menu_type_prefs!=''){
      newfilter_menu_type_prefs = filter_menu_type_prefs.join();
    }else{
      filter_menu_type_prefs=localStorage.getItem('filter_menu_type_prefs');
      if(Array.isArray(filter_menu_type_prefs)) newfilter_menu_type_prefs=filter_menu_type_prefs.join()
    }
    console.log("filter_menu_type_prefs===",filter_menu_type_prefs, newfilter_menu_type_prefs)

    let filter_menu_section_prefs=$("#"+parentdivid+" #menuSections").val();
    let newfilter_menu_section_prefs = null;
    if(filter_menu_section_prefs) newfilter_menu_section_prefs = filter_menu_section_prefs.join();

    let filter_dietary_prefs=$("#"+parentdivid+" #filter_menu_section_prefs").val();
    let all_categoryids=$("#"+parentdivid+" #all_categoryids").val();
    let all_categorynames=$("#"+parentdivid+" #all_categorynames").val();
    let filter_price_range=$("#"+parentdivid+" input[name=filter_price_range]").val();
    let checkboxClosest=$("#"+parentdivid+" input[name=checkboxClosest]").val();

    if(checkboxClosest=='closest'){
     checkboxClosest=$("input[name=checkboxClosest]").val();
    }
    let checkboxFlavor=$("#"+parentdivid+" input[name=checkboxFlavor]").val();

    if(checkboxFlavor=='flavor'){
     checkboxFlavor=$("input[name=checkboxFlavor]").val();
    }
    let checkboxRated=$("#"+parentdivid+" input[name=checkboxRated]").val();
    if(checkboxRated=='rated'){
      checkboxRated=$("input[name=checkboxRated]").val();
    }
    let checkboxValue=$("#"+parentdivid+" input[name=checkboxValue]").val();
    if(checkboxValue=='value'){
      checkboxValue=$("input[name=checkboxValue]").val();
    }
    let checkboxDiscount=$("#"+parentdivid+" input[name=checkboxDiscount]").val();
    if(checkboxDiscount=="discount"){
      checkboxDiscount=$("input[name=checkboxDiscount]").val();
    }

    let checkboxLowCost=$("#"+parentdivid+" input[name=checkboxLowCost]").val();
    if(checkboxLowCost=='lowCost'){
     checkboxLowCost=$("input[name=checkboxLowCost]").val();
    }
    let checkboxMinimumOrder=$("#"+parentdivid+" input[name=checkboxMinimumOrder]").val();

    let checkboxDeliveryFee=$("#"+parentdivid+" input[name=checkboxDeliveryFee]").val();
    let apply_filters=$("#"+parentdivid+" input[name=apply_filters]").val();
    let searchinput=$("#"+parentdivid+" input[name=searchinput]").val();
    if(typeof searchinput=='undefined'){
      searchinput=$("#search").val();
    }
    let locationinput=$("#"+parentdivid+" input[name=locationinput]").val();
    console.log("head-location-input-search locinput====checking====",locationinput);
    if(!locationinput || typeof locationinput=='undefined'){
       locationinput = $("#head-location-input-search").val();
    }
    console.log("locinput=",locationinput);
    let filter_rating='';
    let locals_rating='';
    let delivery_fee='';
    let filter_distance='';
    let delivery_time='';
    let happyhour='';
    if(sortorders==false){
      filter_rating=$("#"+parentdivid+" .item-star-rating").rateYo("rating");
      locals_rating=$("#"+parentdivid+" .item-star-ratingLocal").rateYo("rating");
      delivery_fee=$("#"+parentdivid+" #home-delivery-fee").val();
      filter_distance=$("#"+parentdivid+" #home-distance-slider").val();
      delivery_time=$("#"+parentdivid+" #home-delivery-time").val();

      if($("#"+parentdivid+" input[name=happyhour]").prop("checked") == true){
        happyhour="on";
      }else{
        happyhour="off";
      }
    }

    if($("#"+parentdivid+" input[name=orderTypeDelivery]").prop("checked") == true){
      orderTypeDelivery="on";
    }else{
      orderTypeDelivery="off";
    }
    if($("#"+parentdivid+" input[name=orderTypePickup]").prop("checked") == true){
      orderTypePickup="on";
    }else{
      orderTypePickup="off";
    }
    if($("#"+parentdivid+" input[name=orderTypeOrderAhead]").prop("checked") == true){
      orderTypeOrderAhead="on";
    }else{
      orderTypeOrderAhead="off";
    }
    //add the filter to url, incase reload is done
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('delivery_fee', delivery_fee);
    urlParams.set('delivery_time', delivery_time);
    urlParams.set('happyhour', happyhour);
    urlParams.set('filter_menu_type_prefs',filter_menu_type_prefs);
    urlParams.set('orderTypeOrderAhead',orderTypeOrderAhead);
    urlParams.set('orderTypePickup',orderTypePickup);
    urlParams.set('orderTypeDelivery',orderTypeDelivery);
    urlParams.set('filter_rating',filter_rating);
    urlParams.set('locals_rating',locals_rating);
    urlParams.set('filter_price_range',filter_price_range);
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({path: newurl}, '', newurl);

    let range=$("input[name=range]").val();
    let radius=$("#header_search_radius_inputbox").val();
    let lat=$("#lat").val();
    let lng=$("#lng").val();
    let googleFlag=$("#"+parentdivid+" input[name=googleFlag]").val();
    let homepage = false;
    let primaryType = document.getElementById("primaryType").value ;

    $.ajax({
        url: "/doSearch",
        data: { "filter_meet_dietary_prefs": filter_meet_dietary_prefs,filter_dietary_prefs: filter_dietary_prefs,filter_menu_section_prefs:newfilter_menu_section_prefs, newfilter_menu_type_prefs,filter_menu_type_prefs,all_categoryids: all_categoryids, all_categorynames: all_categorynames,filter_price_range:filter_price_range,checkboxClosest:checkboxClosest,checkboxFlavor:checkboxFlavor,checkboxRated:checkboxRated,checkboxValue:checkboxValue,checkboxDiscount:checkboxDiscount,apply_filters:apply_filters,searchinput:searchinput,locationinput:locationinput,orderTypePickup:orderTypePickup,orderTypeDelivery:orderTypeDelivery,orderTypeOrderAhead:orderTypeOrderAhead,range: range,radius:radius,lat:lat,lng:lng,primaryType:primaryType,googleFlag:googleFlag, homepage: homepage,checkboxMinimumOrder:checkboxMinimumOrder,checkboxDeliveryFee:checkboxDeliveryFee,checkboxLowCost:checkboxLowCost,filter_rating:filter_rating,locals_rating:locals_rating,delivery_fee:delivery_fee,filter_distance:filter_distance,delivery_time:delivery_time,happyhour:happyhour, locationinput: locationinput},
        type: 'post',
        error: function(e){
          $(`#serach-loader`).css('display', 'none');
        },
        success: function(results){

          $("input[name=locationinput]").val(locationinput);
          $("input[name=searchinput]").val(searchinput);
          //$(`#intro-container`).css('display', 'none');
          //to display results
          if(results){
             $('#homepagesearch').html('');
      	     $('#search-menuItemsDiv').html('');
              resultsMenu = results.resultsMenuItems;
              resultsVenues = results.resultsVenues;
              mapDataMenuItems = results.mapDataMenuItems;
              mapDataVenues = results.mapDataVenues;
              searchStringRestaurant = searchinput;
              searchStringMenuItem =  searchinput;
              venuesDetails= results.venuesDetails;
              searchResults(primaryType,1); //this populates and displays
              //set the restaurant, menu tab
             if(document.getElementById("showNoSalesOnTheSystemMessage")){
               let val = document.getElementById("showNoSalesOnTheSystemMessage").value;
               if(document.getElementById("missing_restaurant_ordertype")){
                 if(val==true || val=="true") document.getElementById("missing_restaurant_ordertype").style.display = "block";
                 else document.getElementById("missing_restaurant_ordertype").style.display = "none";
               }
             }
	          get_search_discounts(primaryType);
            setTimeout(function() {
              
              $(`#search_filter_divmobile`).css('display', 'block');
            },500);
          }
          eventBookMark();
        }
  });
}
$(document).ready(function(){
  eventBookMark();
});
//accordions on advanced search
    //show both accordions as expanded on page load
    $("#collapseMenuItem").collapse('show');
    $("#collapseRestaurant").collapse('show');
    // for advance-locationinputsearch
    $(document).on('change', '#advance-locationinputsearch', function() {
      if($('#advance-locationinputsearch').val().trim().length==0){
        $("#advance-lat").val(Cookies.get('posLat'));
        $("#advance-lng").val(Cookies.get('posLon'));
      } else {
        $("#advance-lat").val("");
        $("#advance-lng").val("");
      }
    });

    // for top header filter popup
    $(document).on('change', '#headersearch-locationinputsearch', function() {
    if($('#headersearch-locationinputsearch').val().trim().length==0){
//      if(location is blank, set to cookies lat,lng
      $("#headersearch-lat").val(Cookies.get('posLat'));
      $("#headersearch-lng").val(Cookies.get('posLon'));
    } else {
      $("#headersearch-lat").val("");
      $("#headersearch-lng").val("");
    }
    });

  const addBookMark = function(event = null){
    let venueId, menuItemId, data, isBookMark
    if(sessionStorage.bookmark_recorded){
      try{
        let book_mark = JSON.parse(sessionStorage.book_mark)
        venueId = book_mark.venue_id
        menuItemId = book_mark.item_id
        let elem = $(`.bookmark-icon-home[data-bookmark-id="${venueId}-${menuItemId}"]`)
        isBookMark = elem.hasClass('bookmark-fas')
        sessionStorage.removeItem('bookmark_recorded')
      }catch(err){
        return false
      }
    }else if(event){
      venueId = $(event.currentTarget).data('venueid');
      menuItemId = $(event.currentTarget).data('menuitemid');
      isBookMark = $(event.currentTarget).hasClass('bookmark-fas');
     // $(event.currentTarget).css('color', isBookMark ? '': 'green');
    }else{
      return false
    }

    data = menuItemId ? { menuItemId, venueId } : { venueId };

    let user_id = $('#userId').val();
    if(!user_id) {
      let book_mark = {
        venue_id: venueId,
        item_id: menuItemId,
      }
      sessionStorage.bookmark_recorded = JSON.stringify(book_mark)
      return window.location.assign('/users/login?next=' + location.pathname);
    }

    if(isBookMark) {
      $.ajax({
        url: '/wishListController/removeWishList',
        method: 'POST',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        dataType: 'json',
      }).then(function(response){
        if (response.code === 0) {
          $(event.currentTarget).removeClass('fas');
          $(event.currentTarget).removeClass('bookmark-fas');
          $(event.currentTarget).addClass('far');
          $(event.currentTarget).addClass('bookmark-far');
          swal("Success", response.message, "success");
        } else {
          swal("Oops", response.message, "error");
        }
      })
    } else {
      $.ajax({
        url: '/wishListController/addWishList',
        method: 'POST',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        dataType: 'json',
      }).then(function(response){
        if (response.code === 0) {
          $(event.currentTarget).addClass('fas');
          $(event.currentTarget).addClass('bookmark-fas');
          $(event.currentTarget).removeClass('far');
          $(event.currentTarget).removeClass('bookmark-far');
          swal("Success", response.message, "success");
        } else {
          swal("Oops", response.message, "error");
        }
      })
    }
  }
//redesign do we need to call this on page load  addBookMark()

  // var userid  = $('#userId').val();
  // let wishlist = [];
  // if(userid){

  //   $.ajax({
  //     url: '/wishListController/getWishList',
  //     method: 'GET',
  //     dataType: 'json',
  //     success: function (result) {
  //       wishlist = result.data;
  //     },
  //   });
  // }
  function eventBookMark(){
    $(".intro-container").find('.bookmark-icon-home').unbind();
    $("#nearby-restaurants-container").find('.bookmark-far').unbind();
    $("#nearby-restaurants-container").find('.bookmark-fas').unbind();
    $("#search_results_div").find('.bookmark-far').unbind();
    $("#search_results_div").find('.bookmark-fas').unbind();

    $(".intro-container").on('click', '.bookmark-icon-home', function(events){
      addBookMark(events);
    });
    $("#nearby-restaurants-container").on('click', '.bookmark-far', function(events){
      addBookMark(events);
    });
    $("#nearby-restaurants-container").on('click', '.bookmark-fas', function(events){
      addBookMark(events);
    });
    $("#search_results_div").on('click', '.bookmark-far', function(events){
      addBookMark(events);
    });
    $("#search_results_div").on('click', '.bookmark-fas', function(events){
      addBookMark(events);
    });
    $("#results").on('click', '.bookmark-fas', function(events){
      addBookMark(events);
    });
    $("#results").on('click', '.bookmark-far', function(events){
      addBookMark(events);
    });
  }
//commeting this on load  eventBookMark();
  let user_id = $('#userId').val();
  if(!user_id){
    $(".preferences").css("display","none");
  }else{
   $(".preferences").css("display","block");
  }
  function search_tab_clicked_user(value) {
    //set to session incase page is reloaded
    if (sessionStorage) sessionStorage.setItem("primaryType",value);
    search_tab_clicked(value);
   }

  function search_tab_clicked(value) {
    let userLoggedIn = $('#userId').val();
    if(userLoggedIn)
    {
// return dislike amd wishlist
      $.ajax({
        url: '/wishListController/getWishList',
        method: 'GET',
        async: false,
        dataType: 'json',
        success: function (result) {
          if(result && result.data){
            wishlistData = result.data.wishlist;
            dislikesData=result.data.likes;
          }
        },
      });
    }
    primaryType = value;
    document.getElementById("primaryType").value = primaryType;
   
    if(value=='menuitem' || value==null || value=='undefined'){
      $('#missing-restaurant-div1').hide();
      $("#search-menuItemsDiv").show();
      $("#search-menuItemsDiv").text("Your search returned no menu items. ")
    }
    const $FORM_PRIVATE_ROOMS = $('.private-rooms');
    const $FORM_SEARCH_BASIC = $('#form-search-basic');
      let venueprivateroomsContainer = $('.nearby-restaurants-privaterooms-container');
     if(value=="privateroom"){
        $('#searchbutton').hide();
        $FORM_PRIVATE_ROOMS.show();
        $FORM_SEARCH_BASIC.hide();
        venueprivateroomsContainer.show();
          $('#searching-results').hide();
          $('#room-searching-results').show();
    } else {
      $('#searchbutton').show();
      $FORM_PRIVATE_ROOMS.hide();
      $FORM_SEARCH_BASIC.show();
      venueprivateroomsContainer.hide();
      if(value=="menuitem" || value=="restaurant"){
        if (window.location.href.indexOf("basicsearch") > -1) {

  	    //hide te search results filter
  	    if(value=='restaurant'){
          $("#search_filter_price").css("display","none")
  	      $(".search_filter_rating").css("display","none")
  	      $(".filter_locals_rating").css("display","none")
  	      $(".rating_color_description").hide();

          console.log("$('#missing-restaurant-div2').css('display')===",$('#missing-restaurant-div2').css('display'))
          if($('#missing-restaurant-div2').css('display') == 'block' || $('#missing-restaurant-div2').css('display')=='' ){
            $("#missing-restaurant-div1").css("display","none")
          }else{
             $("#missing-restaurant-div1").css("display","block")
          }
  	      //show the i dont see my rest here button
          $("#nosearchresult").css("display","flex");
  	       if(document.getElementById("missing_restaurant_1")){
  		      document.getElementById("missing_restaurant_1").style.display = "inline-block";
  	         $("#nosearchresult").css("display","none");
           }
  	    }else{
           $("#search_filter_price").css("display","block")
  	       $(".search_filter_rating").css("display","block")
  	       $(".filter_locals_rating").css("display","block")
           $("#missing-restaurant-div1").css("display","none")
  	       $(".rating_color_description").show();
           $("#nosearchresult").css("display","none");
  	      //hide the i dont see my rest here button
  	       if(document.getElementById("missing_restaurant_1")){
  		      document.getElementById("missing_restaurant_1").style.display = "none";
  	       }
  	    }
         //set the tab
         $('#searchnavpill .nav-item').each(function(index){
              let activeItem = $(this).find(".nav-link");
              if(activeItem.attr('data-key')==value){
                 $(this).find('.nav-link').addClass('active');
              }else{
                $(this).find('.nav-link').removeClass('active');
              }
          });
         searchResults(value,1);
        }else{
          showHomepage(value,orderTypeDelivery,orderTypePickup,orderTypeOrderAhead, false);
        }
      }
      }
   }
   $(document).on("change",".pickup",function(e) {
    let primaryType = $(".home-primary-type").val();
    let selectedsort = this.id;
     let parentElm = $(this).parent();
      if(selectedsort=="orderTypeDelivery" || selectedsort=="header_filter_orderTypeDelivery" || selectedsort=="filter_orderTypeDelivery" ){
        if($(this).prop("checked") == true){
          orderTypeDelivery="on";
          $("input[name=orderTypeDelivery]").val("on");
          $("input[name=orderTypeDelivery]").prop('checked', true);
          $('#home-delivery-ordertype span').css({'color': '#02843d'});
          if(parentElm) parentElm.attr('data-value', 'on');
          $('#home-delivery-ordertype .fa-check').show();
          if(newurl!='homepage'){
            $("#home-delivery-ordertype .fa-check").removeAttr('style');
            $('#home-delivery-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});
          }else if(newurl=='homepage'){
            $("#home-delivery-ordertype .fa-check").removeAttr('style');
            $('#home-delivery-ordertype .fa-check').css({'background-color': '#02843d',  'color': 'white'});
          }
        }
        else{
          if(newurl!='homepage'){
             $("#home-delivery-ordertype .fa-check").removeAttr('style');
            $('#home-delivery-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          }else{
            $("#home-delivery-ordertype .fa-check").removeAttr('style');
            $('#home-delivery-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          
          }
           orderTypeDelivery="off";
           $("input[name=orderTypeDelivery]").val("off");
           $("input[name=orderTypeDelivery]").prop('checked', false);
           $('#home-delivery-ordertype span').css({'color': '#707070'});
           if(parentElm) parentElm.attr('data-value', 'off');
      }
    }

      else if(selectedsort=="orderTypePickup"  || selectedsort=="header_filter_orderTypePickup" || selectedsort=="filter_orderTypePickup"){
        if($(this).prop("checked") == true){
          $("input[name=orderTypePickup]").val("on");
          orderTypePickup="on";
          $("input[name=orderTypePickup]").prop('checked', true);
          $('#home-pick-up-ordertype span').css({'color': '#02843d'});
          if(parentElm) parentElm.attr('data-value', 'on');
          $('#home-pick-up-ordertype .fa-check').show();
            if(newurl!='homepage'){
              $("#home-pick-up-ordertype .fa-check").removeAttr('style');
              $('#home-pick-up-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});
            }  else if(newurl=='homepage'){
              $("#home-pick-up-ordertype .fa-check").removeAttr('style');
              $('#home-pick-up-ordertype .fa-check').css({'background-color': '#02843d',  'color': 'white'});
            }
        }
        else{
          if(newurl!='homepage'){
            $("#home-pick-up-ordertype .fa-check").removeAttr('style');
            $('#home-pick-up-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          }else{
            $("#home-pick-up-ordertype .fa-check").removeAttr('style');
            $('#home-pick-up-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          }
          orderTypePickup="off";
          $("input[name=orderTypePickup]").val("off");
          $("input[name=orderTypePickup]").prop('checked', false);
          $('#home-pick-up-ordertype span').css({'color': '#707070'});
          if(parentElm)  parentElm.attr('data-value', 'off');
          
        }
      }
      else if(selectedsort=="orderTypeOrderAhead" || selectedsort=="header_filter_orderTypeOrderAhead" || selectedsort=="filter_orderTypeOrderAhead"){
         if($(this).prop("checked") == true){
          orderTypeOrderAhead="on";
          $("input[name=orderTypeOrderAhead]").val("on");
          $("input[name=orderTypeOrderAhead]").prop('checked', true);
          $('#home-orderahead-ordertype span').css({'color': '#02843d'});
          if(parentElm) parentElm.attr('data-value', 'on');
          $('#home-orderahead-ordertype .fa-check').show();
          if(newurl!='homepage'){
            $("#home-orderahead-ordertype .fa-check").removeAttr('style');
            $('#home-orderahead-ordertype .fa-check').css({'background-color': '#02843d', 'color': 'white'});
          }
          else if(newurl=='homepage'){
            $("#home-orderahead-ordertype .fa-check").removeAttr('style');
            $('#home-orderahead-ordertype .fa-check').css({'background-color': '#02843d',  'color': 'white'});
          }
        }
        else{
          if(newurl!='homepage'){
            $("#home-orderahead-ordertype .fa-check").removeAttr('style');
            $('#home-orderahead-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          }else{
            $("#home-orderahead-ordertype .fa-check").removeAttr('style');
            $('#home-orderahead-ordertype .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          }
          orderTypeOrderAhead="off";
           $("input[name=orderTypeOrderAhead]").val("off");
           $("input[name=orderTypeOrderAhead]").prop('checked', false);
           $('#home-orderahead-ordertype span').css({'color': '#707070'});
           if(parentElm) parentElm.attr('data-value', 'off');

        }
      
      }
      if(selectedsort=="homeToprated" ){
        if($(this).prop("checked") == true){
          homeToprated="on";
          $("input[name=homeToprated]").val("on");
          $("input[name=homeToprated]").prop('checked', true);
          $('#home-toprated span').css({'color': '#707070'});
          if(parentElm) parentElm.attr('data-value', 'on');
          $('#home-toprated .fa-check').show();
          $("#home-toprated .fa-check").removeAttr('style');
          $('#home-toprated .fa-check').css({'background-color': '#02843d',  'color': 'white'});
          
        }
        else{
          $("#home-toprated .fa-check").removeAttr('style');
          $('#home-toprated .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
           homeToprated="off";
           $("input[name=homeToprated]").val("off");
           $("input[name=homeToprated]").prop('checked', false);
           $('#home-toprated span').css({'color': '#707070'});
           if(parentElm) parentElm.attr('data-value', 'off');
      }
    }
    else if(selectedsort=="homeCloset" ){
        if($(this).prop("checked") == true){
          $("input[name=homeCloset]").val("on");
          homeCloset="on";
          $("input[name=homeCloset]").prop('checked', true);
          $('#home-closet span').css({'color': '#707070'});
          if(parentElm) parentElm.attr('data-value', 'on');
          $('#home-closet .fa-check').show();
          $("#home-closet .fa-check").removeAttr('style');
          $('#home-closet .fa-check').css({'background-color': '#02843d',  'color': 'white'});
        
        }
        else{
          $("#home-closet .fa-check").removeAttr('style');
          $('#home-closet .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          homeCloset="off";
          $("input[name=homeCloset]").val("off");
          $("input[name=homeCloset]").prop('checked', false);
          $('#home-closet span').css({'color': '#707070'});
          if(parentElm)  parentElm.attr('data-value', 'off');
          
        }
      }
      else if(selectedsort=="mostFreeFood" ){
         if($(this).prop("checked") == true){
          $("input[name=mostFreeFood]").val("on");
          $("input[name=mostFreeFood]").prop('checked', true);
          $('#home-mostfreefood span').css({'color': '#707070'});
          if(parentElm) parentElm.attr('data-value', 'on');
          $('#home-mostfreefood .fa-check').show();
          $("#home-mostfreefood .fa-check").removeAttr('style');
          $('#home-mostfreefood .fa-check').css({'background-color': '#02843d',  'color': 'white'});
        }
        else{
          $("#home-mostfreefood .fa-check").removeAttr('style');
          $('#home-mostfreefood .fa-check').css({'border': '1px solid #02843D', 'background-color': '', 'box-sizing': 'border-box', 'color': 'white'});
          mostFreeFood="off";
           $("input[name=mostFreeFood]").val("off");
           $("input[name=mostFreeFood]").prop('checked', false);
           $('#home-mostfreefood span').css({'color': '#707070'});
           if(parentElm) parentElm.attr('data-value', 'off');
        }
      }
      else if(selectedsort=="happyhour"){
        if($(this).prop("checked") == true){
          if(parentElm) parentElm.attr('data-value', 'on');
        }
        else{
           if(parentElm) parentElm.attr('data-value', 'off');
        }
      }
      let url=window.location.href;
      let currenturl=url.split('/').pop().split('.').shift();
      if( currenturl=='' || currenturl=='homepage'){
        
        insertParam("orderTypeDelivery", orderTypeDelivery) 
        insertParam("orderTypeOrderAhead", orderTypeOrderAhead) 
        insertParam("orderTypePickup", orderTypePickup) 
      }
      if(window.location.href.indexOf("newurl") > -1){
        startSearch("search_filter_div");
       }else if(window.location.href.indexOf("homepage") > -1) {
        showHomepage(primaryType,orderTypeDelivery,orderTypePickup,orderTypeOrderAhead, true);
      }
    });
  var pagenumberN=1;
  $(document).on('click', '#nearby-happy-hourview', function() {
      pagenumberN++;
      if($(this).data('value') && $(this).data('value').trim() =='menues'){
        prepareUIHomePageMenuItems(pagenumberN,'#nearby-happy-hour');
        $("#recommended_friendsback").css('visibility', 'visible');
      }else{
        prepareUIHomePageVenues(pagenumberN,'#nearby-happy-hour');
        $("#recommended_friendsback").css('visibility', 'visible');
      }
      showImages();
  });
  $(document).on('click', '#nearby-happy-hourback', function() {
      pagenumberN--;
      if($(this).data('value') && $(this).data('value').trim() =='menues'){
        prepareUIHomePageMenuItems(pagenumberN,'#nearby-happy-hour');
        if(pagenumberN==1){
          $("#recommended_friendsback").css('visibility', 'hidden');
        }else{
          $("#recommended_friendsback").css('visibility', 'visible');
        }
      }else{
        prepareUIHomePageVenues(pagenumberN,'#nearby-happy-hour');
        if(pagenumberN==1){
          $("#recommended_friendsback").css('visibility', 'hidden');
        }else{
          $("#recommended_friendsback").css('visibility', 'visible');
        }

      }
      showImages();
  });
  var pagenumberF=1;
  $(document).on('click', '#recommended_friendsviews', function() {
      pagenumberF++;
      if($(this).data('value') && $(this).data('value').trim() =='menues'){
        prepareUIHomePageMenuItems(pagenumberF,'#recommended_friends');
        $("#recommended_friendsback").css('visibility', 'visible');
      }else{
        prepareUIHomePageVenues(pagenumberF,'#recommended_friends');
        $("#recommended_friendsback").css('visibility', 'visible');

      }
      showImages();
  });
  $(document).on('click', '#recommended_friendsback', function() {
      pagenumberF--;
      if($(this).data('value') && $(this).data('value').trim() =='menues'){
        prepareUIHomePageMenuItems(pagenumberF,'#recommended_friends');
        if(pagenumberF==1){
          $("#recommended_friendsback").css('visibility', 'hidden');
        }else{
          $("#recommended_friendsback").css('visibility', 'visible');
        }
      }else{
        prepareUIHomePageVenues(pagenumberF,'#recommended_friends');
      }
      showImages();
  });
  var pagenumberR=1;

  var pagenumberL=1;
  $(document).on('click', '#recommended-by-localsview', function() {
      pagenumberL++;
      if($(this).data('value')  && $(this).data('value').trim() =='menues'){
        prepareUIHomePageMenuItems(pagenumberL,'#recommended-by-locals');
        $("#recommended-by-localsback").css('visibility', 'visible');

      }else{
        $("#recommended-by-localsback").css('visibility', 'visible');

        prepareUIHomePageVenues(pagenumberL,'#recommended-by-locals');
      }
      showImages();
  });
   $(document).on('click', '#recommended-by-localsback', function() {
      pagenumberL--;
      if($(this).data('value')  && $(this).data('value').trim() =='menues'){
        prepareUIHomePageMenuItems(pagenumberL,'#recommended-by-locals');
        if(pagenumberL==1){
          $("#recommended-by-localsback").css('visibility', 'hidden');
        }else{
          $("#recommended-by-localsback").css('visibility', 'visible');
        }
      }else{
        prepareUIHomePageVenues(pagenumberL,'#recommended-by-locals');
        if(pagenumberL==1){
          $("#recommended-by-localsback").css('visibility', 'hidden');
        }else{
          $("#recommended-by-localsback").css('visibility', 'visible');
        }
      }
      showImages();
  });
  var pagenumberD=1;
  $(document).on('click', '#dietpref-venuesview', function() {
      pagenumberD++;
      $("#dietpref-venuesback").css('visibility', 'visible');

      prepareUIHomePageVenues(pagenumberD,'#dietpref-venues');
      showImages();
  });
  $(document).on('click', '#dietpref-venuesback', function() {
      pagenumberD--;

      prepareUIHomePageVenues(pagenumberD,'#dietpref-venues');
      if(pagenumberD==1){
        $("#dietpref-venuesback").css('visibility', 'hidden');

      }else{
        $("#dietpref-venuesback").css('visibility', 'visible');

      }
      showImages();
  });
  let nearby_menuitems='';
  let nearby_venues='';
  let nearby_venuesDetails='';

  let local_menuitems='';
  let local_venues='';
  let local_venuesDetails='';

  let default_menuitems=''
  let default_venues=''
  let default_venuesDetails='';
  let default_dietpref_venues='';

  let friends_menuitems='';
  let friends_venues='';
  let friends_venuesDetails='';


  var lat='';
  var lng='';
  /* browser lat, lng detection not happeing now
  $('#lat').on('change', () => {// the function is triggering before the function load
  // if locn disallowed,, control will not come here  $('#lat').on('change', () => {
    lat = $('#lat').val();
    lng = $('#lng').val();
  });
  */
   const isHomePage = window.location.pathname.startsWith('/homepage');
//this also gets triggerred on load when lat changes and hence shows homepage sections
  function showHomepage(primaryType,orderTypeDelivery="on",orderTypePickup="on",orderTypeOrderAhead="on", getData=true){
  //use the sessionstored primaryType if exists, since thats the tab selected by user
    // this checke is wrong don't know who put this pne
    /*if($("#inputSwitchSpan").text()=='Current Location')
    {*/
      lat = $('#lat').val();
      lng = $('#lng').val();
    /*}*/
    // if (sessionStorage) {
    //   if(sessionStorage.getItem("primaryType")) {
    //     primaryType  = sessionStorage.getItem("primaryType");
    //      sessionStorage.removeItem('primaryType')
    //    }
    // }

        let searchClicked = false;
        let tfield = 'q';
        let turl = window.location.href;
        if(turl.indexOf('?' + tfield + '=') != -1)
        searchClicked = true;
        else if(turl.indexOf('&' + tfield + '=') != -1)
        searchClicked = true;
        let locationinput='Enter Your Location';
        //if(newurl!='homepage'){

        locationinput = $("#head-location-input-search").val();
        // get value location
        if (!locationinput || locationinput === 'Enter Your Location') {
          const homeInputSearchElement = $("#homepage-location-input-search");
          if (homeInputSearchElement) {
            locationinput = homeInputSearchElement.val() || '';
          }
        }
        /*}else{
           locationinput = $("#homepage-location-input-search").val();
        }*/
       // setlocationlocalforage(locationinput, '', '', null)
        if (
          isHomePage &&
          (locationinput) &&
          !searchClicked
        ) {
          $('#recommended-by-locals').html('');
          $('#nearby-happy-hour').html('');
          $('#highestRated-container').html('');
          $('#recommended_friends').html('');
          $('#dietpref-venues').html('');
          //to set view more pags numbers back to 1
          pagenumberL=1;
          pagenumberR=1;
          pagenumberF=1;
          pagenumberN=1;
          if(getData){ //get homepage data
            $(`#serach-loader`).css('display', 'block');
            let primaryTypeHasData = false;
            let ajaxCallsCompleted = 0;
//            console.log("starting ajax calls for homepage", new Date());
            $.ajax({
                url: "/homePageDoSearchMenuItem",
                data: {
                  lat: lat,
                  lng: lng,
                  homepage: true,
                  searchType: 'local',
                  locationinput,
                  primaryType,
                  orderTypeDelivery,
                  orderTypePickup,
                  orderTypeOrderAhead,
                }, //'local'.'friends','default','nearby'
                type: 'post',
                dataType:'json'
            }).then(function(datalocal){
                //$(`#serach-loader`).css('display', 'none');
//                console.log("ajax call for datalocal completed...", new Date());
                if(datalocal){
                  local_menuitems = datalocal.resultsMenuItems;
                  local_venues = datalocal.resultsVenues;
                  local_venuesDetails = datalocal.venuesDetails;
                  if(primaryType=='menuitem'){
                    if(!local_menuitems || local_menuitems.length==0) {
                      console.log("no menu item for local from db")
                    } else {
                      primaryTypeHasData = true;
                    }
                  } else {
                    if(!local_venues || local_venues.length==0) {
                      console.log("no local_venues item for local from db")
                    } else {
                      primaryTypeHasData = true;
                    }
                  }
                }
                ajaxCallsCompleted++;
                switchPrimaryType(primaryType, primaryTypeHasData, ajaxCallsCompleted )
            });
//            console.log("starting ajax calls for homepage for friends", new Date());
            $.ajax({
                url: "/homePageDoSearchMenuItem",
                data: {lat: lat, lng: lng, homepage: true, 'searchType': 'friends',  locationinput: locationinput, 'primaryType':primaryType,'orderTypeDelivery':orderTypeDelivery,'orderTypePickup':orderTypePickup,'orderTypeOrderAhead':orderTypeOrderAhead}, //'local'.'friends','default','nearby'
                type: 'post',
                dataType:'json'
            }).then(function (datafriends){
                //$(`#serach-loader`).css('display', 'none');
//                console.log("ajax call for datafriends completed...", new Date());
                if (datafriends) {
                  friends_menuitems = datafriends.resultsMenuItems;
                  friends_venues = datafriends.resultsVenues;
                  friends_venuesDetails = datafriends.venuesDetails;
                  if (primaryType=='menuitem') {
                    if((!friends_menuitems || friends_menuitems.length==0) || !user_id) {
                      console.log("no menu item for friends from db")
                    } else {
                      primaryTypeHasData = true;
                    }
                   } else {
                       if((!friends_venues || friends_venues.length==0) ) {
                        console.log("no venue for friends from db");
                       } else {
                        primaryTypeHasData = true;
                      }
                  }
                }
                ajaxCallsCompleted++;
                switchPrimaryType(primaryType, primaryTypeHasData, ajaxCallsCompleted )
              });
//            console.log("starting ajax calls for homepage for default", new Date());
            $.ajax({
                url: "/homePageDoSearchMenuItem",
                data: {lat: lat, lng: lng, homepage: true, 'searchType': 'default',  locationinput: locationinput, 'primaryType':primaryType,'orderTypeDelivery':orderTypeDelivery,'orderTypePickup':orderTypePickup,'orderTypeOrderAhead':orderTypeOrderAhead}, //'local'.'friends','default','nearby'
                type: 'post',
                dataType:'json'
            }).then( function(datadefault) {
              //$(`#serach-loader`).css('display', 'none');
/*                console.log(
                  "ajax call for default completed...",
                  datadefault,
                  new Date(),
                  { lat, lng }
                );
*/                if(datadefault ){
                  default_menuitems = datadefault.resultsMenuItems;
                  default_venues = datadefault.resultsVenues;
                  default_venuesDetails = datadefault.venuesDetails;
                  default_dietpref_venues = datadefault.resultsVenuesDietPref;
                  if(primaryType=='menuitem'){
                    if(!default_menuitems || default_menuitems.length==0) {
                      console.log("no menu item for default from db");
                    } else {
                      primaryTypeHasData = true;
                    }
                  } else {
                    if(!default_venues || default_venues.length==0) {
                      console.log("no default_venues item for default from db")
                    } else {
                        primaryTypeHasData = true;
                    }
                  }
                }
                ajaxCallsCompleted++;
                switchPrimaryType(primaryType, primaryTypeHasData, ajaxCallsCompleted )
           });
//            console.log("starting ajax calls for homepage for nearby", new Date());
            $.ajax({
                url: "/homePageDoSearchMenuItem",
                data: {lat: lat, lng: lng, homepage: true, 'searchType': 'nearby',  locationinput: locationinput, 'primaryType':primaryType,'orderTypeDelivery':orderTypeDelivery,'orderTypePickup':orderTypePickup,'orderTypeOrderAhead':orderTypeOrderAhead}, //'local'.'friends','default','nearby'
                type: 'post',
                dataType:'json'
            }).then(  function(data)  {
              //$(`#serach-loader`).css('display', 'none');
//                console.log("datanearby ajax calls completed...",  data,new Date());
                if(data ){
                  nearby_menuitems = data.resultsMenuItems;
                  nearby_venues = data.resultsVenues;
                  nearby_venuesDetails = data.venuesDetails;
                  if(primaryType=='menuitem'){
                    if(!nearby_menuitems || nearby_menuitems.length==0) {
                      console.log("no nearby_menuitems for nearby from db")
                    } else {
                      primaryTypeHasData = true;
                     }
                  } else {
                    if(!nearby_venues || nearby_venues.length==0) {
                      console.log("no venues for nearby from db")
                    } else {
                      primaryTypeHasData = true;
                    }
                  }
                }
                ajaxCallsCompleted++;
                switchPrimaryType(primaryType, primaryTypeHasData, ajaxCallsCompleted )
            });
          } //getdata for homepage
          else {
            prepareUIHomePage(primaryType)
          }
      } //home page
      else {
         console.log("not showing homepgae check these...isHomePage,searchClicked,lat,lng,searchClicked, locationinput...", isHomePage,searchClicked,lat,lng,locationinput) ;
      }
  }


//if no data exists in the passed primaryType, show the other primarytype data and also select that button
function switchPrimaryType(primaryType, primaryTypeHasData, ajaxCallsCompleted ){
  if (ajaxCallsCompleted == 4) {
    console.log("switchPrimaryType called with...", {
      primaryType,
      primaryTypeHasData,
      ajaxCallsCompleted,
    });
    
    if (!primaryTypeHasData) {
      const prev = primaryType;
    primaryType = primaryType === 'menuitem' ? 'restaurant' : 'restaurant';
      document.getElementById("primaryType").value = primaryType;
      console.log("switched primaryType from %s to %s because no data...", prev, primaryType);
    }
    // select the button
    $('#searchnavpill .nav-item').each(function(index){
      let val = String($(this).text()).trim().toLowerCase();
      if(!!val && (
        (val === "menu items" && primaryType === "menuitem") ||
        (val === "restaurants" && primaryType === "restaurant")
      )) {
        $(this).find('.nav-link').addClass('active');
      } else {
        $(this).find('.nav-link').removeClass('active');
      }
    });

    prepareUIHomePage(primaryType);
  }
}


  function prepareUIHomePage(primaryType){
  //tbd set the primarytype button; also set the loader off
    let user_id = $('#userId').val();
    if(primaryType=='menuitem'){
      if(!local_menuitems || local_menuitems.length==0) {
        //console.log("no menu item for local")
        $("#recommendedbyLocalsDiv").css("display","none") ;
      } else {
        $("#recommendedbyLocalsDiv").css("display","flex") ;
        prepareUIHomePageMenuItems(1,'#recommended-by-locals');
      }
      //friends menuitems
      if(!user_id){
        $("#showrecommendedfriendsDiv").css("display","flex");
        $("#recommended_friends").css("display","none");
        $('#recommended_friendsviewsDiv').css("display", "none");
      }else{
        if(!friends_menuitems || friends_menuitems.length==0) {
           $("#showrecommendedfriendsStatic").css("display","flex");
           $('#recommended_friendsviewsDiv').css("display", "none");
        } else {
          $("#showrecommendedfriendsStatic").css("display","none");
          $("#showrecommendedfriendsDiv").css("display","flex");
          $("#recommended_friends").css("display","inline-block");
          $('#recommended_friendsviewsDiv').css({"display": "flex", "justify-content": "space-between"});
          prepareUIHomePageMenuItems(1,'#recommended_friends');
        }
      }
      //default menuitems
      if(!default_menuitems || default_menuitems.length==0) {
          $("#homepageHighestRatedDiv").css("display","none");
      } else {
        $("#homepageHighestRatedDiv").css("display","flex");
        prepareUIHomePageTopRatedMenuItems(1,"#highestRated-container");
      }
      //nearby
      if(!nearby_menuitems || nearby_menuitems.length==0) {
        $('#nearbyHappyhrDiv').css("display","none");
      } else {
        $('#nearbyHappyhrDiv').css("display","flex");
        prepareUIHomePageMenuItems(1,'#nearby-happy-hour');

       }
       $("#dietPrefVenuesDiv").css("display","none");
    } else { //venues
        if(!local_venues || local_venues.length==0) {
          $("#recommendedbyLocalsDiv").css("display","none") ;
        } else {
          $("#recommendedbyLocalsDiv").css("display","flex") ;
          prepareUIHomePageVenues(1,'#recommended-by-locals');
        }
        //friends venues
        if(!user_id){
          $("#showrecommendedfriendsDiv").css("display","flex");
          $("#recommended_friends").css("display","none");
          $('#recommended_friendsviewsDiv').css("display", "none");
        } else {
         if((!friends_venues || friends_venues.length==0) ) {
          $("#showrecommendedfriendsStatic").css("display","flex");
          $('#recommended_friendsviewsDiv').css("display", "none");
        } else {
          $('#recommended_friendsviewsDiv').css({"display": "flex", "justify-content": "space-between"});
          prepareUIHomePageVenues(1,'#recommended_friends');
        }
       }
     //default section venues
      if(!default_venues || default_venues.length==0) {
          $("#homepageHighestRatedDiv").css("display","none");
          $("#dietPrefVenuesDiv").css("display","none");
        } else {
          $("#homepageHighestRatedDiv").css("display","flex");
          prepareUIHomePageTopRatedVenues(1,"#highestRated-container");
        //also show the diet pref restaurant
	    if(default_dietpref_venues && default_dietpref_venues.length > 0 && user_id) {
          $("#dietPrefVenuesDiv").css("display","flex");
          prepareUIHomePageVenues(1,'#dietpref-venues');
        } else {
          $("#dietPrefVenuesDiv").css("display","none");
        }
      }
      //nearby venues
      if(!nearby_venues || nearby_venues.length==0) {
        $('#nearbyHappyhrDiv').css("display","none");
      } else {
        $('#nearbyHappyhrDiv').css("display","flex");
        prepareUIHomePageVenues(1,'#nearby-happy-hour');
      }
    } //end primarytype restaurant
    showImages();
  }

  function showImages(){
      $(`#serach-loader`).css('display', 'none');
      //Call the script that fetches images for every restaurant and manipulates src tag
      //of img for div class 'item-image-container'; this is important to show images
       let script = "/js/result-pictures.js";
       $("head").append('<script type="text/javascript" src="' + script + '"></script>');
       //get and show discounts; adding in timeout, so that the process runs in bg
  setTimeout(function() {
          get_search_discounts(primaryType);
          getYelpRating();
          getYelpRatingcarousel();
  }, 100);
   }
  function getYelpRatingcarousel(){
      // Object to hold impressions data
      let homePagecarouselImpressionsData = {
        userId:$("#account-id").val(),
        venues: []
      };
      $('.yelpresults-contentcarousel').each(function(index, element) {
      // Pushing venueId values to venues array for impressions data
          homePagecarouselImpressionsData.venues.push($(this).find('#venue-id').val());
          let indexval=index+1;
          let parentContainercarousel = ".yelpresults-containercarousel" + indexval;
          let  $yelpRatingContainercarousel = $(parentContainercarousel).find('.yelp-rating-stars')
          let venueParams = {
              name: ($(this).find('#venues-name').val() || '').trim(),
              location: ($(this).find('#venue-location').val() || '').trim(),
              lat: ($(this).find('#venue-lat').val() || '').trim(),
              lng: ($(this).find('#venue-lng').val() || '').trim(),
              phone: ($(this).find('#venue-phone').val() || '').trim()
          };
          //Get yelp ratings
          if ($yelpRatingContainercarousel) {
              $($yelpRatingContainercarousel).getYelpRatings({
                  requestParams: venueParams
              })
          }
          if (indexval === $('.yelpresults-contentcarousel').length-1) {
          $.promisedAjax({
              url: '/analytics/homepageimpressions',
              type: 'POST',
              data: JSON.stringify(homePagecarouselImpressionsData),
              contentType: 'application/json'
          }).catch((err) => console.error(err))
          }
      });
  }
   function getYelpRating(){
      // Object to hold impressions data
      let homePageImpressionsData = {
        userId:$("#account-id").val(),
        venues: []
      };
      $('.yelpresults-content').each(function(index, element) {
      // Pushing venueId values to venues array for impressions data
          homePageImpressionsData.venues.push($(this).find('#venue-id').val());
          let parentContainer = ".yelpresults-container" + index;
          let venueParams = {
              name: ($(this).find('#venue-name').text() || '').trim(),
              location: ($(this).find('#venue-location').val() || '').trim(),
              lat: ($(this).find('#venue-lat').val() || '').trim(),
              lng: ($(this).find('#venue-lng').val() || '').trim(),
              phone: ($(this).find('#venue-phone').val() || '').trim(),
              venueId: ($(this).find('#venue-id').val() || '').trim()
          };
          let $yelpRatingContainer = $(parentContainer).find('.yelp-rating-stars')

          //Get yelp ratings
          if ($yelpRatingContainer) {
              $($yelpRatingContainer).getYelpRatings({
                  requestParams: venueParams
              })
          }
          if (index === $('.yelpresults-content').length-1) {
          $.promisedAjax({
              url: '/analytics/homepageimpressions',
              type: 'POST',
              data: JSON.stringify(homePageImpressionsData),
              contentType: 'application/json'
          }).catch((err) => console.error(err))
          }
      });
  }
  /*prepareUIHomePageMenuItems data for home page */
  function prepareUIHomePageMenuItems(pagenumber, homepagediv){
    let itemContainerDiv='';
    let menueratingsection='';
    let menues=default_menuitems;
    let venuesDetails=default_venuesDetails;
    if(homepagediv=='#recommended-by-locals'){
     menues=local_menuitems.slice((pagenumber-1)*4);
     venuesDetails=local_venuesDetails;
      $('#recommended-by-localsspan').html('');
      if(menues.length>4){
        $('#recommended-by-localsspan').append(`<a href="javascript:void(0)" id="recommended-by-localsback" style="visibility:hidden; " data-value="menues" ><i class="fas fa-angle-left pr-2 font-18"></i>Go back &nbsp;  &nbsp; |  &nbsp;  &nbsp; </a>`);

        $('#recommended-by-localsspan').append(`<a href="javascript:void(0)" id="recommended-by-localsview" data-value="menues" >View More <i class="fas fa-angle-right font-18 pl-2"></i></a>`);
      } else if (pagenumberL > 1 ) {
        $('#recommended-by-localsspan').append(`<a href="javascript:void(0)" id="recommended-by-localsview"  data-value="menues" >View Again <i class="fas fa-angle-right font-18 pl-2"></i></a>`);
        pagenumberL=0;
      }
    }
    else if(homepagediv=='#recommended_friends'){
     menues=friends_menuitems.slice((pagenumber-1)*4);
     venuesDetails=friends_venuesDetails;
      $('#recommended_friendsspan').html('');
      if(menues.length>4){
        $('#recommended_friendsspan').append(`<a href="javascript:void(0)" id="recommended_friendsback"  data-value="menues" style="visibility:hidden"><i class="fas fa-angle-left pr-2 font-18"></i>Go back &nbsp;  &nbsp; |  &nbsp;  &nbsp; </a>`);

        $('#recommended_friendsspan').append(`<a href="javascript:void(0)" id="recommended_friendsviews"  data-value="menues" >View More <i class="fas fa-angle-right font-18 pl-2"></i></a>`);
      } else if (pagenumberF > 1 ) {
        $('#recommended_friendsspan').append(`<a href="javascript:void(0)" id="recommended_friendsviews"  data-value="menues" >View Again <i class="fas fa-angle-right font-18 pl-2"></i></a>`);
        pagenumberF=0;
      }
    }
    else if(homepagediv=='#nearby-happy-hour'){
     menues=nearby_menuitems.slice((pagenumber-1)*4);
     menues=nearby_menuitems;
     venuesDetails=nearby_venuesDetails;
     $('#nearby-happy-hourspan').html('');
     if(menues.length>4){
        $('#nearby-happy-hourspan').append(`<a href="javascript:void(0)" id="nearby-happy-hourback" style="visibility:hidden; " data-value="menues" ><i class="fas fa-angle-left pr-2 font-18"></i>Go back &nbsp;  &nbsp; |  &nbsp;  &nbsp; </a>`);
        $('#nearby-happy-hourspan').append(`<a href="javascript:void(0)" id="nearby-happy-hourview"  data-value="menues">View More <i class="fas fa-arrow-right font-18 pl-2"></i></a>`);
      } else if (pagenumberN > 1 ) {
        $('#nearby-happy-hourspan').append(`<a href="javascript:void(0)" id="nearby-happy-hourview"  data-value="menues">View Again <i class="fas fa-arrow-right font-18 pl-2"></i></a>`);
        pagenumberN=0;
      }
    }
    if(menues && menues.length>0){
      let adminflavormaxValue=$("#flavorMatchPercentForColorGreen").val();
      let adminflavorminValue=$("#flavorMatchPercentForColorRed").val();

      for(let i=0;i<menues.length;i++) {
        if(i<4){
          let venueDetailsForMenu = null;
           if(venuesDetails && venuesDetails.length>0) {
              venueDetailsForMenu = venuesDetails.find(x=>x.venueId == menues[i].venueId);
               if(!venueDetailsForMenu) {
                 continue;
              }
           }
          else{
            //console.log("ODDD, venue not found for this menuitem...", menues[i].venueId);
            continue;
          }
          if(homepagediv=='#recommended_friends' && menues[i].ratedByUserName){
            let ratingDiv='';
            let myrate=false;
            let rateUniqueId = 'home-recommend-'+menues[i].menuitem.menuItemId;
            if(menues[i].menuitem.upcRating && Number(menues[i].menuitem.upcRating) > 0){
              console.log("ratingDiv=4===",menues[i].menuitem.upcRating,Number(menues[i].menuitem.upcRating))
             
              ratingDiv =`${loadRate(menues[i].menuitem.upcRating, myrate, rateUniqueId)}`;
            }
            if(menues[i].menuitem.skuRating && Number(menues[i].menuitem.skuRating) > 0){
              console.log("ratingDiv=31===",menues[i].menuitem.skuRating,Number(menues[i].menuitem.skuRating))
             
              ratingDiv =`${loadRate(menues[i].menuitem.skuRating, myrate, rateUniqueId)}`;
            }
            if(menues[i].starRating && Number(menues[i].starRating) > 0){
              console.log("ratingDiv=2===",menues[i].starRating,Number(menues[i].starRating))
              
              ratingDiv =`${loadRate(menues[i].menuitem.starRating, myrate, rateUniqueId)}`;
            }
            if(menues[i].menuitem.avgRating && Number(menues[i].menuitem.avgRating) > 0){
              console.log("ratingDiv=1===",myrate,Number(menues[i].menuitem.avgRating))
              ratingDiv =`${loadRate(menues[i].menuitem.avgRating, myrate, rateUniqueId)}`;
            }
            
            
           
            menueratingsection=`<div class="menueratingsection mb-2">
              <div class="ml-2 font-14" style="float:left">${menues[i].ratedByUserName}</div>
              <div style="float:right" href="javascript:void(0)" onClick="displayReviews(this)" class="hvr-underline-from-center star-rating" data-item-id="${menues[i].menuitem.menuItemId}" data-reviews="${menues[i].menuitem.reviewsCount}" data-venue-id="${menues[i].venueId}" style="cursor: pointer;width: fit-content;">
                 ${ratingDiv}
              </div>
             </div>`;
            }
            
          
          let menuHtml = renderSearchMenuItem(menues[i], venueDetailsForMenu, null, adminflavormaxValue, adminflavorminValue, menueratingsection, i, homepagediv);
          itemContainerDiv += menuHtml;
        }
      }; //end of for loop
    }
    //add to div
    let venueContainer = $(homepagediv);
    if(venueContainer){
      venueContainer.html(itemContainerDiv);
     } else {
     // console.log("menuitemcontainer div not found...", homepagediv);
    }
  } //end of prepareUIMenuItems

	let rateid = 0;
	function loadRate(itemRating, myrate, rateUniqueId) {
   let ratehtml = document.createElement('div');
   	  if(typeof(itemRating)!=="undefined"){
	    itemRating = Number(itemRating);
	    if(itemRating <0) itemRating = 0;
	    if(itemRating > 5) itemRating = 5;
	    ratehtml.innerHTML = "<div class='rate-heart-plate' data-id=" + rateid + " id=" + rateUniqueId + " data-rating=" + itemRating + "></div>";
	    $(ratehtml.children).rateYo({
	      rating: itemRating,
	      starWidth: "16px",
	      spacing: "2px",
	      ratedFill: "green",
	      normalFill: "#bdbcbc",
	      starSvg: myrate ? __platerate_icons.my_heart_plate : __platerate_icons.heart_plate
	    });
	  }
	  rateid = rateid + 1;
	  return ratehtml.innerHTML;
	}

// top rated menuitem carousel; showing 1 item per page
  function prepareUIHomePageTopRatedMenuItems(pagenumber, homepagediv){
    let itemContainerDiv='';

    let menueratingsection='';
    //let menues=default_menuitems;
    let venuesDetails=default_venuesDetails;
    let adminflavormaxValue=$("#flavorMatchPercentForColorGreen").val();
    let adminflavorminValue=$("#flavorMatchPercentForColorRed").val();
    itemContainerDiv +=`<div class="col-md-12"><div class="menuitemslick">`
    let index=0;
    let menues=default_menuitems.slice((pagenumber-1)*1);
    menues.forEach((inMenuitem,index) => {
      let menuHtml = '';
      if(index<=1){
      let venueDetailsForMenu = null;
        if(venuesDetails && venuesDetails.length>0) {
              venueDetailsForMenu = venuesDetails.find(x=>x.venueId == inMenuitem.venueId);
               if(!venueDetailsForMenu) {
	               return;
              }
       }
       else{
         return;
       }

       let flavorMatch=-1;
       if(Number(inMenuitem.flavorMatch)>=0){
         flavorMatch= Number(inMenuitem.flavorMatch);
       }
       let reviewCount ='';
       if(inMenuitem.menuitem && inMenuitem.menuitem.reviewsCount){
          reviewCount = `<div id="item-review-count"  class="rev topreview" >(${inMenuitem.menuitem.reviewsCount})`
          reviewCount+= `</div>`
       }
    let itemDesc = '';
    let itemDescCutoff = '';
    let itemDescHover = '';
    let menuitemName = '';
    let displaymenuitemName='';
    let menuitemNameHalf='';
    let venueSlug = inMenuitem.venueId;
    if(venueDetailsForMenu && venueDetailsForMenu.slug ){
       venueSlug = venueDetailsForMenu.slug;
    }
    itemDesc=inMenuitem.menuitem.description;
    itemDescHover=inMenuitem.menuitem.description;
      if(itemDesc && itemDesc.length !=null){
       itemDescCutoff =  itemDesc.length > 135 ? itemDesc.substring(0,135)+'....' : itemDesc;
        const searchTermRegex = new RegExp(`${itemDesc}`,'gi');
        if(itemDesc.length > 130){
            itemDescCutoff= itemDescCutoff.replace(searchTermRegex, `<span class="">${itemDesc}</span>`);
            itemDesc = `<p class="item-description" data-toggle="tooltip" data-placement="bottom" title="${itemDescHover }" >
              ${ itemDescCutoff }
            </p>`;
        }else{
      itemDesc= itemDesc.replace(searchTermRegex, `<span class="">${itemDesc}</span>`);
          itemDesc = `<p class="item-description" >
            ${ itemDesc }
            </p>`;
        }
      }

    let venueUrl="/restaurant/"+venueSlug;
    if(inMenuitem.menuitem && inMenuitem.menuitem.name){
      menuitemName=inMenuitem.menuitem.name;
      let newmenuitemName= menuitemName.replace(/&apos..../g, "\'").replace(/&Apos..../g, "\'")
     if($(window).width() < 424){
      if(menuitemName.length>19){
            menuitemNameHalf = newmenuitemName.substring(0,18)+'....';
            displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItem=${inMenuitem.menuitem.slug}&menuItemLink=${inMenuitem.menuitem.menuItemId}" data-toggle="tooltip" data-placement="bottom" title="${inMenuitem.menuitem.name}" class="black font-20 col-md-4 pl-0 item-name ">
                  ${menuitemNameHalf}
              </a>`;
       } else {
                displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItem=${inMenuitem.menuitem.slug}&menuItemLink=${inMenuitem.menuitem.menuItemId}" class="black font-20 col-md-4 pl-0 item-name ">
                                    ${newmenuitemName}
                                </a>`;
        }
      }  else{
          if(menuitemName.length>16){
            menuitemNameHalf = newmenuitemName.substring(0,16)+'....';
            displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItem=${inMenuitem.menuitem.slug}&menuItemLink=${inMenuitem.menuitem.menuItemId}" data-toggle="tooltip" data-placement="bottom" title="${inMenuitem.menuitem.name}" class="black font-20 col-md-4 pl-0 item-name ">
                  ${menuitemNameHalf}
              </a>`;
             } else {
                      displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItem=${inMenuitem.menuitem.slug}&menuItemLink=${inMenuitem.menuitem.menuItemId}" class="black font-20 col-md-4 pl-0 item-name ">
                                          ${newmenuitemName}
                                      </a>`;
              }
        }
    }

    let inmenuItemId = inMenuitem.menuitem.menuItemId
    let buyOrRateBtn=`
          <button class="btn-rate rate-item-home mt-0" id="home-rate-button-${inmenuItemId }" data-venueslug="${venueSlug}" data-venueid="${ inMenuitem.venueId}" data-menuitemid ="${inmenuItemId }">Rate</button>
     `;
    let cardshowflag=false;
    if(venueDetailsForMenu && venueDetailsForMenu.salesOnTheSystem && inMenuitem.menuitem && inMenuitem.menuitem.menuType!=1) {
     buyOrRateBtn=`<button class="addbtn-cart mt-0" id="home-buy-button-${inmenuItemId }" onclick="addItemToCart('${inMenuitem.venueId}','${inmenuItemId }','${venueSlug }')" > <i class="fa fa-shopping-cart "></i> Add</button>`;
     cardshowflag=true;
    }
    let ratingDiv=prepareRatingDiv(inMenuitem);
    let hiddenDataDiv = prepareHiddenDataDiv(inMenuitem, venueSlug);

    let isBookMark = false;
    let isLiked=false

    if(userLoggedIn){
      if(inMenuitem && inMenuitem.venueId){
        const findBookMark = wishlistData ? wishlistData.some((element) => element.venueId === inMenuitem.venueId && element.menuItemId===inmenuItemId && element.userID ===userLoggedIn) : false;
        if(findBookMark )
        isBookMark = true;
        const findLike= dislikesData ? dislikesData.some((element) => element.venueId === inMenuitem.venueId && element.menuItemId===inmenuItemId && element.userID ===userLoggedIn) : false;
        if(findLike )
        isLiked = true;

      }
    }
    let venueitemName='';
    if(inMenuitem.name && inMenuitem.name!=='undefined'){
        venueitemName= inMenuitem.name.replace(/&apos..../g, "\'").replace(/&Apos..../g, "\'");
    }

    let flavorsDiv=prepareFlavorsDiv(inmenuItemId,menuitemName, inMenuitem, venueitemName,inMenuitem.venueId, flavorMatch, adminflavorminValue, adminflavormaxValue,"menuitem",homepagediv);

    let price=0;
    if(inMenuitem.menuitem && inMenuitem.menuitem.price && inMenuitem.menuitem.price.trim()!='Unknown'){
     price= inMenuitem.menuitem.price
    }else{
      if(inMenuitem.menuitem && inMenuitem.menuitem.addons && inMenuitem.menuitem.addons.length>0 && inMenuitem.menuitem.addons[0].addons && inMenuitem.menuitem.addons[0].addons.length>0 && inMenuitem.menuitem.addons[0].addons[0].price ){
       price=(inMenuitem.menuitem.addons[0].addons[0].price).toFixed(2)//inMenuitem.menuitem.addons[0].price
      }
   }

    let friends_locals_influencer='';
    let menuratingDiv='';
     friends_locals_influencer+=`<div class="carausalmenuitem" >`;

    if((inMenuitem.ratedByFriends && inMenuitem.ratedByFriends.length>0) || (inMenuitem.ratedByInfluencers && inMenuitem.ratedByInfluencers.length>0) || (inMenuitem.ratedByLocals && inMenuitem.ratedByLocals.length>0) ){
        if(inMenuitem.ratedByFriends.length>0){
          for(let i=0;i<inMenuitem.ratedByFriends.length;i++) {
            let userLoggedIn = $('#userId').val();
            if(inMenuitem && inMenuitem.ratedByFriends[i].userId && inMenuitem.ratedByFriends[i].userId ==userLoggedIn){
              let myrate = true;
              let myrateId = 'home-irated-'+inMenuitem.ratedByFriends[i].menuItemId;

              if(inMenuitem.ratedByFriends[i].starRating && inMenuitem.ratedByFriends[i].starRating > 0){
                menuratingDiv = `${loadRate(inMenuitem.ratedByFriends[i].starRating, myrate, myrateId)}`;
              }
            }else{
              let myrate = false;
              let otherRateId = 'home-otherrated-'+inMenuitem.ratedByFriends[i].menuItemId;
              if(inMenuitem.ratedByFriends[i].starRating && inMenuitem.ratedByFriends[i].starRating > 0){
                menuratingDiv = `${loadRate(inMenuitem.ratedByFriends[i].starRating, myrate, otherRateId)}`;
              }
            }

           friends_locals_influencer+=`<div class="col-12 mt-4 pl-md-2 pr-md-2 pl-1 pr-2" style="box-shadow: 0 2px 8px  #F1F1F1;"><div class="row"><div class="col-md-8 col-8 pr-0 pl-2 pl-md-3 form-row"><a href="/publicprofile/${inMenuitem.ratedByFriends[i].userId}" target="_blank"><div class="rateByFriends" style="background-image: url('../images/friends-bg.png');">
            <img class="menuimageeclipse" src="${inMenuitem.ratedByFriends[i].imageUrl ? inMenuitem.ratedByFriends[i].imageUrl :'/images/tempfoodnotext.png'}" img-type="Menu Item"></div></a>
                         <div>${inMenuitem.ratedByFriends[i].userName}</div></div>
            <div class="col-md-4 col-4 mt-2"> <div class='rate-heart-plate'  data-rating="${inMenuitem.ratedByFriends[i].starRating} ">${menuratingDiv}</div></div></div></div>`;
          }
        }
        if(inMenuitem.ratedByInfluencers.length>0){
           for(let i=0;i<inMenuitem.ratedByInfluencers.length;i++) {
           let userLoggedIn = $('#userId').val();
            if(inMenuitem && inMenuitem.ratedByInfluencers[i].userId ==userLoggedIn){
              let myrate = true;
              let myrateId = 'home-irated-'+inMenuitem.ratedByInfluencers[i].menuItemId;

              if(inMenuitem.ratedByInfluencers[i].starRating && inMenuitem.ratedByInfluencers[i].starRating > 0){
                menuratingDiv = `${loadRate(inMenuitem.ratedByInfluencers[i].starRating, myrate, myrateId)}`;
              }
            }else{
              let myrate = false;
              let otherRateId = 'home-otherrated-'+inMenuitem.ratedByInfluencers[i].menuItemId;
              if(inMenuitem.ratedByInfluencers[i].starRating && inMenuitem.ratedByInfluencers[i].starRating > 0){
                menuratingDiv = `${loadRate(inMenuitem.ratedByInfluencers[i].starRating, myrate, otherRateId)}`;
              }
            }
           friends_locals_influencer+=`<div class="col-12 mt-4 pl-md-2 pr-md-2 pl-1 pr-2" style="box-shadow: 0 2px 8px  #F1F1F1;"><div class="row"><div class="col-md-8 col-8 pr-0 pl-md-3 pl-2 form-row"><a href="/publicprofile/${inMenuitem.ratedByInfluencers[i].userId}" target="_blank"><div class="rateByInfluencer" style="background-image: url('../images/Influencer-bg.png');">
            <img class="menuimageeclipse" src="${inMenuitem.ratedByInfluencers[i].imageUrl ? inMenuitem.ratedByInfluencers[i].imageUrl :'/images/tempfoodnotext.png'}" img-type="Menu Item"></div></a>
                         <div>${inMenuitem.ratedByInfluencers[i].userName}</div></div>
            <div class="col-md-4 col-4 mt-2">${menuratingDiv}</div></div></div>`;
          }
        }
        if(inMenuitem.ratedByLocals.length>0){
           for(let i=0;i<inMenuitem.ratedByLocals.length;i++) {
            if(inMenuitem && inMenuitem.ratedByLocals[i].userId == userLoggedIn){
              let myrate = true;
              let myrateId = 'home-irated-'+inMenuitem.ratedByLocals[i].menuItemId;

              if(inMenuitem.ratedByLocals[i].starRating && inMenuitem.ratedByLocals[i].starRating > 0){
                menuratingDiv = `${loadRate(inMenuitem.ratedByLocals[i].starRating, myrate, myrateId)}`;
              }
            }else{
              let myrate = false;
              let otherRateId = 'home-otherrated-'+inMenuitem.ratedByLocals[i].menuItemId;
              if(inMenuitem.ratedByLocals[i].starRating && inMenuitem.ratedByLocals[i].starRating > 0){
                menuratingDiv = `${loadRate(inMenuitem.ratedByLocals[i].starRating, myrate, otherRateId)}`;
              }
            }
           friends_locals_influencer+=`<div class="col-12 mt-4 pl-md-2 pr-md-2 pl-1 pr-2" style="box-shadow: 0 2px 8px  #F1F1F1;"><div class="row"><div class="col-md-8 pr-0 pl-3 form-row"><a href="/publicprofile/${inMenuitem.ratedByLocals[i].userId}" target="_blank"><div class="rateByLocals" style="background-image: url('../images/locals-bg.png');">
            <img class="menuimageeclipse" src="${inMenuitem.ratedByLocals[i].imageUrl ? inMenuitem.ratedByLocals[i].imageUrl :'/images/tempfoodnotext.png'}" img-type="Menu Item"></div></a>
                         <div>${inMenuitem.ratedByLocals[i].userName}</div></div>
            <div class="col-md-4"> ${menuratingDiv}</div></div></div>`;
          }

        }
      friends_locals_influencer+=`
        <div class="invitefriends col-md-9 ml-md-4 ml-0 mt-3 mb-3" style="display: ${(inMenuitem.ratedByFriends.length ===0 && inMenuitem.ratedByInfluencers.length === 0 && inMenuitem.ratedByFriends.length === 0 ? "block": "none")}"><a  href="/invite-friends"><p style="text-align:center;padding:10px;font-size: 12px !important;" class="font-18 green">
                Invite friends & earn ${typeof customerRevenueSharesValue!=="undefined" && customerRevenueSharesValue  ? "$"+customerRevenueSharesValue :  ""} in credit</p></a></div></div>`;
   }else{
    friends_locals_influencer+=`<div class="mt-5 font-20 darkgrey" >See everyone’s favorites</div>
      <div class="">* See what menu items friends liked nearby</br>
      * Find food that meets your:<br/>
      - Dietary preferences</br>
      - Flavor Preferences</br>
      <p>
      Introduce friends to your new favorite food & drink app!</p></div>
        <div class="invitefriends col-md-9 ml-md-4 ml-0 mt-3 mb-3" style="display: ${(inMenuitem.ratedByFriends.length ===0 && inMenuitem.ratedByInfluencers.length === 0 && inMenuitem.ratedByFriends.length === 0 ? "block": "none")}"><a  href="/invite-friends"><p style="text-align:center;padding:4px;font-size: 12px !important;" class="font-18 green">
                Invite friends & earn ${typeof customerRevenueSharesValue!=="undefined" && customerRevenueSharesValue ? "$"+customerRevenueSharesValue :  ""} in credit</p></a></div></div>`;

    }

    let deliveryTime = venueDetailsForMenu && venueDetailsForMenu.deliveryMaxTime ? venueDetailsForMenu.deliveryMaxTime : 30
    let deliveryFeesDiv=prepareDeliveryFeesDiv(venueDetailsForMenu);
    menuHtml +=
    ` <div class="results-col ">
            <div class="front1">
                <div class="face front">
                <div class="row">
                ${hiddenDataDiv}
                    <div  class="col-md-7 mt-0 ml-md-3 ml-2 mr-2 carouselflip image-lazy-load go-to-specified-restaurant pr-md-0 mr-md-2" style="padding-left:0px; padding-right:0px;">
                      <a href="/restaurant/${venueSlug}?menuItemLink=${inMenuitem.menuitem.menuItemId}" class="orange item-name pr-0">
                      <img class="craousalitemImage itemImage" id="${inMenuitem.menuitem.menuItemId}"
                      src="/images/tempfoodnotext.png" img-type="Menu Item"  venue-id="${inMenuitem.venueId}">

                      </a>
                      <div id="toprated_discountdiv_${inMenuitem.menuitem.menuItemId}"></div>
                       <div class="form-row carausallike-wishlist-container topwishlist">
                          <a href="javascript:void(0)" class="whistlist"><i  class="over-image-iconstop float-right ${ isBookMark ? 'fas bookmark-fas' : 'far bookmark-far'} fa-bookmark mr-0" style="cursor: pointer;"
                             data-menuItemId=${inMenuitem.menuitem.menuItemId} data-venueId=${inMenuitem.venueId} ></i></a>
                      </div>
                       <div href="javascript:void(0)" onClick="displayReviews(this)" class="hvr-underline-from-center star-rating mobileratingdiv" data-item-id="${inMenuitem.menuitem.menuItemId}" data-reviews="${inMenuitem.menuitem.reviewsCount}" data-venue-id="${inMenuitem.venueId}" style="cursor: pointer;width: fit-content;" >
                         ${ratingDiv}
                         ${reviewCount}
                      </div>
                      <div  class="form-row carausallike-wishlist-container bottomwishlist">
                          <a><i  onClick="likeFunc(this)"  class="over-image-iconstop float-left fa-w-16 fa-flip-horizontal fa-flip-vertical ${isLiked? 'fas likewishlist-fas': 'far likewishlist-far' } fa-thumbs-up" aria-hidden="true" data-venueid="${inMenuitem.venueId}" data-user-id="${userLoggedIn}"></i></a>
                      </div>

                      <div class="row ">
                      <div class="col-md-12 col-xl-8 item-details pr-1 pl-1 pl-md-3 pr-md-2" >
                       <div class="row">
                          <div class="form-row col-md-8 ml-md-3 ml-0 carousel-item-Name" style="float:left;">
                              <div class="col-md-9 col-9 mt-1">${displaymenuitemName}</div>
                              <span class="col-md-3 col-3 green font-16 pr-0 pl-2 home-item-price">$ ${price}</span>

                          </div>
                         <div class="col-md-2 mt-2 pl-0 ratingdiv" style="float:right;" href="javascript:void(0)" onClick="displayReviews(this)" class="hvr-underline-from-center star-rating mt-1" data-item-id="${inMenuitem.menuitem.menuItemId}" data-reviews="${inMenuitem.menuitem.reviewsCount}" data-venue-id="${inMenuitem.venueId}" style="cursor: pointer;width: fit-content;" >
                             ${ratingDiv}
                             ${reviewCount}
                          </div>
                        </div>
                        <div class="col-md-12 pl-2 home-carausal-items">${itemDesc}</div>
                        <div class="row">
                          <div class="col-md-5 ml-md-3 pl-md-2 pl-2" style="float:left;">
                          <a href="${venueUrl}" style=color:#4A4A4A !important" class="font-16">${inMenuitem.name}</a>
                        </div>
                          <div class="col-md-6 col-12 mt-md-1 mt-3 pr-0 pl-md-0 pl-2 mb-md-0 mb-3 text-right" style="float:right;">
                          <span style="color:#4A4A4A;" class="font-10">
                          <img src="/images/clock.png" style="display:unset;width:unset" /> &nbsp;Time&nbsp;&nbsp; <img src="/images/time.png"  style="display:unset;width:unset" />&nbsp; ${deliveryTime}Min&nbsp;&nbsp;
                          <img src="/images/truck.png" style="display:unset;width:unset" />&nbsp;${deliveryFeesDiv}</span>
                          </div>
                        </div>
                        <div  class=" col-12 col-md-12 mt-3 pl-md-2 pr-md-2 carousel-buy-btn pr-2 pl-2" >
                          <div class="desktopbybutton"> ${buyOrRateBtn}</div>
                        </div>
                      </div>

                      <div class="col-md-12 col-xl-4 mt-md-4 mt-3 homepage-prefs-div pr-md-4" style="float:right;">
                       ${flavorsDiv}
                        <div class="marginTopMbl pl-md-2 "  >
                            <a class="row mobilebybutton" style="margin-left:-10px">${buyOrRateBtn}
                            </a>
                        </div>
                      </div>
                    </div>

                    </div>
                    <div class="col-md-4" style="overflow-x:auto;">
                      <div class="mt-4">
                      <div class="row localinfufreind mr-4" style="display: ${(inMenuitem.ratedByFriends.length ===0 && inMenuitem.ratedByInfluencers.length === 0 && inMenuitem.ratedByFriends.length === 0 ? "none": "")}">
                        <div class="col-md-4 col-4 d-flex py-2" style="border-right:1px solid #EFEFEF" >Friend <img class="ml-2 toprestsectionimg" src="/images/friends-bg.png"></div>
                        <div class="col-md-5 col-5 d-flex py-2" style="border-right:1px solid #EFEFEF" >Influencer <img class="ml-2 toprestsectionimg" src="/images/Influencer-bg.png"></div>
                        <div class="col-md-3 col-3 d-flex py-2">Local <img class="ml-2 toprestsectionimg" src="/images/locals-bg.png"></div>
                      </div>
                      ${friends_locals_influencer}
                      </div>
                    </div>
                    </div>
                    </div>
                </div>

            </div>
     `;
     itemContainerDiv += menuHtml;
   }
    });
    itemContainerDiv +=` </div></div>`
    //add to div


    let venueContainer = $(homepagediv);

    if(venueContainer){
      venueContainer.html(itemContainerDiv);

      $('.menuitemslick').slick({
        dots: false,
        speed: 300,
        swipe: true,
        infinite: false,
        slidesToShow: 1,
        fade: true,
        cssEase: 'linear',
        adaptiveHeight: true,
        prevArrow:"<button id='menusplash-prev' type='button' class='slick-prev pull-left ' style='left:-4%; '><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
        nextArrow:"<button id='menusplash-next' type='button' class='slick-next pull-right' style='right:-4%'><i class='fa fa-angle-right' aria-hidden='true' ></i></button>"
      });
      if(pagenumber==1){
        $('#menusplash-prev').addClass('hidden')
      }
      if(default_menuitems.length/pagenumber<2){
        $('#menusplash-next').addClass('hidden')
      }
    } else {
      // console.log("menuitemcontainer div not found...", homepagediv, itemContainerDiv);
    }

    if(isMobile || $(window).width() < 424){
      $(".mobileratingdiv").css("display","flex");
      $(".ratingdiv").css("display","none");
    }else{
      $(".ratingdiv").css("display","inline-flex");
      $(".mobileratingdiv").css("display","none");
    }
} //end of prepareUIHomePageTopRatedMenuItems
$('body').on('click', '#menusplash-next', function() {
  menunextslideshow();
});

$('body').on('swipe', '.menuitemslick', function(event, slick, direction) {
  if(direction=="left"){
    menunextslideshow();
    $('#menusplash-prev').removeClass('hidden');
  } else {
    menuprevslideshow();
  }
});

//return for example 5% + $1.25, and if none, then return "Free Delivery"
function prepareDeliveryFeesDiv(venueDetailsForMenu){
    let deliveryFeesDiv="";
    if(venueDetailsForMenu && venueDetailsForMenu.deliveryFees){
      if(venueDetailsForMenu.deliveryFees.commissionByBothParties && Number(venueDetailsForMenu.deliveryFees.commissionByBothParties)>0){
        deliveryFeesDiv = venueDetailsForMenu.deliveryFees.commissionByBothParties+"%"
      }
      if(venueDetailsForMenu.deliveryFees.feeByBothParties && Number(venueDetailsForMenu.deliveryFees.feeByBothParties)>0){
        if(deliveryFeesDiv) deliveryFeesDiv += " + $"+venueDetailsForMenu.deliveryFees.feeByBothParties
        else deliveryFeesDiv = "$"+venueDetailsForMenu.deliveryFees.feeByBothParties
      }
    }
    if(!deliveryFeesDiv) deliveryFeesDiv =""
    return deliveryFeesDiv;
}

function menunextslideshow(){
  const primaryType = document.getElementById('primaryType').value;
  pagenumberR++;
  if (primaryType === "menuitem") {


   if(pagenumberR<default_menuitems.length){
      $('.menuitemslick').slick('slickAdd',prepareUIHomePageTopRatedMenuItems(pagenumberR,'#highestRated-container'));
    }
  } else {
    if(pagenumberR<default_venues.length){
      $('.menuitemslick').slick('slickAdd',prepareUIHomePageTopRatedVenues(pagenumberR,'#highestRated-container'));
    }
  }
  showImages();

}
$('body').on('click', '#menusplash-prev', function() {
  menuprevslideshow();
});
function menuprevslideshow(){
  const primaryType = document.getElementById('primaryType').value;
  pagenumberR--;
  if (primaryType === "menuitem") {
    if(pagenumberR==0){
      $('#menusplash-next').addClass('hidden')
    }else{
      $('#menusplash-next').removeClass('hidden')
    }
    if(pagenumberR<default_menuitems.length){
     $('.menuitemslick').slick('slickRemove',prepareUIHomePageTopRatedMenuItems(pagenumberR,'#highestRated-container'));
    }
  } else {
    if(pagenumberR==0){
      $('#menusplash-next').addClass('hidden')
    }else{
      $('#menusplash-next').removeClass('hidden')
    }

    if(pagenumberR<default_venues.length){
      $('.menuitemslick').slick('slickRemove',prepareUIHomePageTopRatedVenues(pagenumberR,'#highestRated-container'));
    }
  }
  showImages();

}


//venueDetailsForMenu is an array with matching venueId
function renderSearchMenuItem(inMenuitem, venueDetailsForMenu, searchStringMenuItem, adminflavormaxValue, adminflavorminValue, menueratingsection, index, homepagediv=null){
   let menuHtml = '<div class="scrolling-wrapper" >';
   let flavorMatch=-1;
    if(Number(inMenuitem.flavorMatch)>=0){
       flavorMatch= Number(inMenuitem.flavorMatch);
    }
    let reviewCount ='';
    if(inMenuitem.menuitem && inMenuitem.menuitem.reviewsCount){
        reviewCount = `<div id="item-review-count" class="mt-2 rev">(${inMenuitem.menuitem.reviewsCount})`
        reviewCount+= `</div>`
    }
    let itemDesc = '';
    let newitemDesc='';
    let itemDescCutoff = '';
    let itemDescHover = '';
    let menuitemName = '';
    let displaymenuitemName='';
    let menuitemNameHalf='';
    let venueSlug = inMenuitem.venueId;
    if(venueDetailsForMenu && venueDetailsForMenu.slug ){
       venueSlug = venueDetailsForMenu.slug;
    }
    if (inMenuitem.menuitem && inMenuitem.menuitem.description) {
        itemDesc=inMenuitem.menuitem.description.replace(/[,.]/g, '');
        itemDescHover=inMenuitem.menuitem.description.replace(/[,.]/g, '');
        if (searchStringMenuItem) {
          let searchTerms = searchStringMenuItem;
            searchTerms = searchTerms.replace(/[,.]/g, '');
            searchTerms = searchTerms.split(' ');
						itemDescCutoff =  itemDescHover.length > 40 ? itemDescHover.substring(0,35)+'....' : itemDescHover;
            searchTerms.forEach((term) => {
              const searchTermRegex = new RegExp(`${term}`,'gi');
		          if(itemDesc.length > 35){
                  // itemDescCutoff= itemDescCutoff.replace(searchTermRegex, `<span class="search-term-highlight">${term}</span>`);
                  // check z-index
				          newitemDesc = `<p class="item-description" data-toggle="tooltip" data-placement="bottom" title="${itemDescHover}" >
					          ${itemDescCutoff}
				          </p>`;
		          }else{

                newitemDesc= itemDesc.replace(searchTermRegex, `<span class="search-term-highlight">${term}</span>`);
			          newitemDesc = `<p class="item-description" data-toggle="tooltip" data-placement="bottom" title="${itemDescHover}" >
                    ${itemDescHover}
                  </p>`;

		          }
          });
        } else {
          if(itemDesc.length > 35){
              itemDescCutoff = itemDesc.substring(0,35)+'....';

              newitemDesc = `<p class="item-description" data-toggle="tooltip" data-placement="bottom" title="${itemDescHover}" >
                            ${itemDescCutoff}
                          </p>`;
          }else{
            newitemDesc = `<p class="item-description" >
                            ${inMenuitem.menuitem.description}
                          </p>`;
          }
       }
    }
    if(inMenuitem.menuitem && inMenuitem.menuitem.name){
	    menuitemName=inMenuitem.menuitem.name;
	    let newmenuitemName= menuitemName.replace(/&apos..../g, "\'").replace(/&Apos..../g, "\'")
        if (searchStringMenuItem) {
          let searchTerms = searchStringMenuItem;
            searchTerms = searchTerms.replace(/[,.]/g, '');
            searchTerms = searchTerms.split(' ');
            searchTerms.forEach((term) => {
              const searchTermRegex = new RegExp(`${term}`,'gi');
		          if(menuitemName.length>19){
			          menuitemNameHalf = newmenuitemName.substring(0,18)+'....';
			          menuitemNameHalf = menuitemNameHalf.replace(searchTermRegex, `<span class="search-term-highlight">${term}</span>`);
			          displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItem=${inMenuitem.menuitem.slug}&menuItemLink=${inMenuitem.menuitem.menuItemId}" data-toggle="tooltip" data-placement="bottom" title="${inMenuitem.menuitem.name}" class="green col-md-8 pl-0 pr-1 item-name item-title">
						          ${menuitemNameHalf}
				          </a>`;
		          }else{
                  newmenuitemName = newmenuitemName.replace(searchTermRegex, `<span class="search-term-highlight">${term}</span>`);
                  displaymenuitemName = `<a href="/restaurant/${venueSlug}?menuItem=${inMenuitem.menuitem.slug}&menuItemLink=${inMenuitem.menuitem.menuItemId}" data-toggle="tooltip" data-placement="bottom" title="${inMenuitem.menuitem.name}" class="green col-md-8 pl-0 pr-1 item-name item-title">
                      ${inMenuitem.menuitem.name}
                  </a>`;

		          }
          });
        } else {
	        if(menuitemName.length>19){
	          menuitemNameHalf = newmenuitemName.substring(0,18)+'....';
	          displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItemLink=${inMenuitem.menuitem.menuItemId}" data-toggle="tooltip" data-placement="bottom" title="${inMenuitem.menuitem.name}" class="green col-md-7 pl-0 item-name item-title">
				          ${menuitemNameHalf}
		          </a>`;
	        } else {
                displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItemLink=${inMenuitem.menuitem.menuItemId}" class="green col-md-7 pl-0 item-name item-title">
                                    ${newmenuitemName}
                                </a>`;
            }

       }
    }
    let inmenuItemId = inMenuitem.menuitem.menuItemId

    let buyOrRateBtn=`
          <button class="btn-rate rate-item-home mt-0" id="home-rate-button-${inmenuItemId }" data-venueslug="${venueSlug}" data-venueid="${ inMenuitem.venueId}" data-menuitemid ="${inmenuItemId }">Rate</button>
     `;
    let cardshowflag=false;
    let deliveryMaxTime = '';
    if(venueDetailsForMenu && venueDetailsForMenu.deliveryMaxTime) {
      deliveryMaxTime = venueDetailsForMenu.deliveryMaxTime
    }
    let deliveryFeesDiv=prepareDeliveryFeesDiv(venueDetailsForMenu);
    if(venueDetailsForMenu && venueDetailsForMenu.salesOnTheSystem && inMenuitem.menuitem.menuType!=1) {
     buyOrRateBtn=`<button class="btn-cart mt-0 text-underlined" id="home-buy-button-${inmenuItemId }" onclick="addItemToCart('${inMenuitem.venueId}','${inmenuItemId }','${venueSlug }')" ><i class="fa fa-shopping-cart "></i>  Add</button>`;
     cardshowflag=true;
    }
    let ratingDiv=prepareRatingDiv(inMenuitem);
    let hiddenDataDiv = prepareHiddenDataDiv(inMenuitem, venueSlug);

    let isBookMark = false;
    let isLiked=false

    if(userLoggedIn){
      if(inMenuitem && inMenuitem.venueId){
        const findBookMark = wishlistData ? wishlistData.some((element) => element.venueId === inMenuitem.venueId && element.menuItemId===inmenuItemId && element.userID ===userLoggedIn) : false;
        if(findBookMark )
        isBookMark = true;
        const findLike= dislikesData ? dislikesData.some((element) => element.venueId === inMenuitem.venueId && element.menuItemId===inmenuItemId && element.userID ===userLoggedIn) : false;
        if(findLike )
        isLiked = true;

      }
    }
    let venueitemName='';
    if(inMenuitem.name && inMenuitem.name!=='undefined'){
        venueitemName= inMenuitem.name.replace(/&apos..../g, "\'").replace(/&Apos..../g, "\'");
    }

    let flavorsDiv=prepareFlavorsDiv(inmenuItemId,menuitemName, inMenuitem, venueitemName,inMenuitem.venueId, flavorMatch,  adminflavorminValue, adminflavormaxValue,"menuitem", homepagediv);
    if(window.location.href.indexOf("basicsearch") > -1){

     $(".topheader").css("height",'0px');
    menuHtml =
    `<div class="menucard pl-md-0">`;
    }else{
      menuHtml =
    ` <div class="menucard pl-md-0">`;
    }
    let deliveryTime = inMenuitem.menuitem.deliveryMaxTime || deliveryMaxTime

    let price=0;
    if(inMenuitem.menuitem.price && inMenuitem.menuitem.price && inMenuitem.menuitem.price!=="Unknown" ){
      price= inMenuitem.menuitem.price
    }else{
      if(inMenuitem.menuitem && inMenuitem.menuitem.addons && inMenuitem.menuitem.addons.length>0 && inMenuitem.menuitem.addons[0].addons && inMenuitem.menuitem.addons[0].addons.length>0 && inMenuitem.menuitem.addons[0].addons[0].price){
       price=(inMenuitem.menuitem.addons[0].addons[0].price).toFixed(2)//inMenuitem.menuitem.addons[0].price
      }
     }


    menuHtml +=
    `
      ${hiddenDataDiv}
      ${menueratingsection}
         <div class="results-col flip" style="${!userLoggedIn? 'height:28.5rem;' :'' }">
            <div class="card">
            <div class="front1">
                <div class="face front">
                    <div class="mune-img  homepageimage image-lazy-load go-to-specified-restaurant">
                      <a href="/restaurant/${venueSlug}?menuItemLink=${inMenuitem.menuitem.menuItemId}" class="orange item-name pr-0">
                        <div class="aspect-ratio-box">
                          <div class="aspect-ratio-box-inside">
                            <img class="itemImage" id="${inMenuitem.menuitem.menuItemId}" src="/images/tempfoodnotext.png" img-type="Menu Item" venue-id="${inMenuitem.venueId}">
                          </div>
                        </div>
                      </a>
                      <div id="discountdiv_${inMenuitem.menuitem.menuItemId}"></div>
                      <div class="form-row like-wishlist-container mb-1"\>
                        <div class="col-md-1 col-1"><a ><i  onClick="likeFunc(this)"  class="over-image-icons float-left fa-w-16 fa-flip-horizontal fa-flip-vertical ${isLiked? 'fas likewishlist-fas': 'far likewishlist-far' } fa-thumbs-up" aria-hidden="true" data-item-id="${inMenuitem.menuitem.menuItemId}"  data-venueid="${inMenuitem.venueId}" data-user-id="${userLoggedIn}"></i></a>
                        </div>
                        <div class="col-md-10 col-10" style="text-align:center">
                            <div href="javascript:void(0)" onClick="displayReviews(this)" class="hvr-underline-from-center star-rating" data-item-id="${inMenuitem.menuitem.menuItemId}" data-reviews="${inMenuitem.menuitem.reviewsCount}" data-venue-id="${inMenuitem.venueId}" style="cursor: pointer;width: fit-content;">
                               ${ratingDiv}
                               ${reviewCount}
                            </div>
                        </div>
                        <div class="col-md-1 col-1"><a href="javascript:void(0)" class="whistlist"><i  class="over-image-icons float-right ${ isBookMark ? 'fas bookmark-fas' : 'far bookmark-far'} fa-bookmark" style="cursor: pointer;"
                            data-menuItemId=${inMenuitem.menuitem.menuItemId} data-venueId=${inMenuitem.venueId} ></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="item-details mt-md-2 pl-1 pr-2">
                        <h2 class="title">
                           ${displaymenuitemName}
                            <span class="price-title mt-2">$ ${price}</span>
                        </h2>
                    </div>
                    <div class="food-details mt-md-0 mt-0">
                        <div class="item-description-container row" >
                          <div class="col-md-12 col-12 pl-md-3 pl-1">  ${newitemDesc}</div>

                        </div>
                        <p class="descriptiontext mt-md-1 pl-md-0 pl-1">
                            <span class="grey"> at </span>
                            <a href="/restaurant/${venueSlug }" id="" class="green" data-toggle="tooltip" data-placement="bottom" title="${venueitemName }">
                                ${venueitemName.length > 30 ? venueitemName.substring(0,30)+'....' : venueitemName}
                            </a>
                        </p>
                        <div class="home-time-dilvery-div mt-md-4 mt-2">
                            <span style="color:#4A4A4A;" class="font-12">
                            <img src="/images/clock.png" style="display:unset;width:unset" /> &nbsp;Time&nbsp;&nbsp; <img src="/images/time.png"  style="display:unset;width:unset" />&nbsp; ${deliveryTime}Min&nbsp;&nbsp;
                            <img src="/images/truck.png" style="display:unset;width:unset" />&nbsp;${deliveryFeesDiv}</span>
                          </div>

                      <div class="row mt-1">
                          <div class="col-md-12 col-12 menuitem_location_miles text-left pl-md-3 pl-1"><i class="fa fa-map-marker grey font-10"></i>
                              <span class="font-12">${ inMenuitem.location.distance  } miles</span>
                          </div>
                      </div>
                    </div>

                    <div class="food-flavor homepage-prefs-div pl-2">
                        ${flavorsDiv}
                         <div class="marginTopMbl pl-md-2"  >
                            <a class="row" style="margin-left:-10px">${buyOrRateBtn}
                            </a>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    `;
  return menuHtml;

}
function homeToggleDietPref(e){
  let divPref = $(e).attr('data-divtype');
	let state = $(e).children().first().text();
	let display = state == "Hide" ? "display : none !important;" : "display : block !important";
	if(state=='Hide'){
    if($(window).width() < 724){
      $("#results .flip").css('height',"30rem")
    }else{
     $("#results .flip").css('height',"29rem")
    }
    $(e).children().first().text('Dietary Preferences?');
		$(e).closest('.homepage-prefs-div').find('.home-diet-flavorMatch').css('display','none');
	}else{
    if(divPref!=="#highestRated-container"){
      $("#results .flip").css('height',"34.5rem")
    }
		$(e).children().first().text('Hide');
		$(e).closest('.homepage-prefs-div').find('.home-diet-flavorMatch').css('display','flex');
	}

}
function prepareFlavorsDiv(inmenuItemId,menuitemName, inMenuitem, venueitemName, venueId, flavorMatch, adminflavorminValue, adminflavormaxValue, topRatedType, homepagediv){
    let flavorsDiv=`<div class="row d-flex pl-0 pr-0 ml-0 mr-0 mt-md-4 mt-2" style="line-height: normal;"><a href="javascript:void(0)"  onclick="homeToggleDietPref(this)" data-divtype="${homepagediv}"><span class=" green m-card-diet-pref-txt responsive-font-9" style="text-decoration-line: underline;line-height-normal">Dietary Preferences?</span></a></div><div style="display:none;"class="row pl-md-2 mt-md-0 mt-1 home-diet-flavorMatch">`;
    let flavorMatchValue='';
    let dietaryPreferencesValue='';
    let dietaryPreferencesStatus='Set';
    let dietaryPreferencesColor='green';
    let expertReviewLowValue='';
    let expertReviewHighValue='';
    let expertReviewValue='';
     let userLoggedIn = $('#userId').val();
    if(flavorMatch && flavorMatch!=='undefined' && Number(flavorMatch)>=0){
        flavorMatchValue=JSON.stringify(flavorMatch);
    }
    if(inMenuitem.dietaryPreferences && inMenuitem.dietaryPreferences!=='undefined'){
        dietaryPreferencesValue=JSON.stringify(inMenuitem.dietaryPreferences);
    }
    if(inMenuitem.dietstatus && inMenuitem.dietstatus!=='undefined'){
        dietaryPreferencesStatus=inMenuitem.dietstatus;
    }
    if(inMenuitem.colordietstatus && inMenuitem.colordietstatus!=='undefined'){
        dietaryPreferencesColor=inMenuitem.colordietstatus;
    }
    if(inMenuitem.expertReview && inMenuitem.expertReview!=='undefined'){
        expertReviewValue=JSON.stringify(inMenuitem.expertReview);
    }
    if(inMenuitem.expertReviewLow && inMenuitem.expertReviewLow!=='undefined'){
        expertReviewLowValue=JSON.stringify(inMenuitem.expertReviewLow);
    }
    if(inMenuitem.expertReviewHigh && inMenuitem.expertReviewHigh!=='undefined'){
        expertReviewHighValue=JSON.stringify(inMenuitem.expertReviewHigh);
    }
    $('[data-toggle="tooltip"]').tooltip({
        trigger : 'hover'
    });
    if(userLoggedIn){
       if(!dietaryPreferencesValue || dietaryPreferencesValue=='[]' || dietaryPreferencesStatus=="Set"){
           if(topRatedType=="menuitem"){ // show diet pref only for  top rated menuitems, not restaurants
      		   flavorsDiv += `
      		    <div class="col-md-6 col-6 pr-0 pl-0">
      		        <span class="col-md-12 col-12 pr-0 pl-0 ml-md-1 diet-flavor-text" style="font-size: 10px">Dietary Preference</span>
   	                 <a href="/users/healthy-eating-profile?tab=collapseFoodPref">
                          <div class="meet-btn meet-btnhome col-md-12 col-12 mb-2 mt-md-2 ml-md-1 mt-2" id="home-diet-pref-status-${inMenuitem.menuItemId}">
                            <i class="fas fa-seedling" style="padding-right:6px"></i>${dietaryPreferencesStatus }
                          </div>
                        </a>
      		    </div>
		    `
           }
        } else {
        if(topRatedType=="menuitem"){ // show diet pref for top rated menuitems only
          flavorsDiv += `
        	    <div class="col-md-6 col-6 pr-0 pl-0 pl-md-2 diet-prefs-container">
                <span class="col-md-12 col-12 pr-0  pl-0 diet-flavor-text" style="font-size: 10px">Dietary Preference</span>
                <a href="javascript:void(0)" data-target="#foodpreferencematch" data-toggle="modal" data-itemid='${inmenuItemId }' data-menuname='${menuitemName }' data-venuename='${venueitemName}' data-venueid='${venueId}' data-dietary-preferences='${dietaryPreferencesValue }' >
                    <div class="meet-btn meet-btnhome col-md-12 col-12 mb-2 mt-md-2 mt-2 text-center" id="home-diet-pref-status-${inMenuitem.menuItemId}">
                      <i class="fas fa-seedling" style="padding-right:6px"></i>${dietaryPreferencesStatus }
                    </div>
                </a>
            </div>
            `
          }
       }
        let flav = 'green';
        if( Number(flavorMatch)>=0 && flavorMatch<adminflavorminValue ){
          flav = 'red'
        }else if( Number(flavorMatch)>=0 && flavorMatch<adminflavormaxValue){
          flav = 'orange'
        }
       if(Number(flavorMatch)>=0){
           flavorsDiv += `<div class="col-md-6 col-6 pl-md-2 pr-md-4"><span class="col-md-12 col-12 pr-0 pl-0 diet-flavor-text" style="font-size: 10px">FlavorMatch<span class="flavor-match-circle">R</span></span>

           <div  data-target="#flavor" class="col-md-5 col-12 pr-1 pl-0" data-toggle="modal" data-menuname='${menuitemName }' data-venuename='${venueitemName}' data-flavor-match-value='${flavorMatchValue }'  data-flavors='${expertReviewValue}' data-flavors-low='${expertReviewLowValue}' data-flavors-high='${expertReviewHighValue}'>
            <a href="javascript:void(0)" class="meet-btn meet-btnhome col-md-12 col-12 mb-2 mt-md-2 text-center mt-2" id="home-flavor-match-${inMenuitem.menuItemId}">
              <i class="fas fa-mortar-pestle black" style="padding-right:6px"></i><b>${flavorMatch > 0? flavorMatch+"%" : "TBD"}</b>
            </a>
          </div>
        </div></div>`;
        } else {
	    flavorsDiv += `<div class="col-md-6 col-6 pl-md-2 pr-md-4"><span class="col-md-12 col-12 pr-0 pl-0 ml-md-1 diet-flavor-text" style="font-size: 10px">FlavorMatch <span class="flavor-match-circle">R</span></span>
            <a href="/users/healthy-eating-profile?tab=collapseTasterProfile" class="meet-btn meet-btnhome col-md-12 col-12 mb-2 mt-md-2 mt-2 text-center" id="home-flavor-match-${inMenuitem.menuItemId}">
              <i class="fas fa-mortar-pestle black" style="padding-right:6px"></i>Set
            </a>
        </div></div>`;
       }
    }else{ //user not logged in
      if(homepagediv=='#highestRated-container'){
        if(topRatedType=="menuitem"){ //dont show diet pref for top rated restaurants menuitems
	       flavorsDiv = `<div class="col-12 dietary_signup mt-3 text-center" ><div class="pt-md-3 pt-1 pl-md-0 pr-md-0 pl-4 pr-4"><a href="/users/register"  class="font-12 home-set-prefs-login darkgrey"><span class="text-underlined green">Sign up </span> & set your dietary & flavor preferences</a></div></div>`;
	} else {
	       flavorsDiv = `
                   <span class="col-md-12 col-12 pr-0 pl-0 diet-flavor-text" style="font-size: 11px">FlavorMatch <span class="flavor-match-circle">R</span></span>
      <div class="dietary_login mt-3 text-center" ><a href="/users/login"  class="font-12"><i class="fas fa-mortar-pestle black" style="padding-right:6px"></i>Login</a></div>`;
	}

      }else{
         flavorsDiv = `<div class="col-12  mt-2 mb-3 text-center" style="text-decoration-line:underline" ></div>`;
      }
    }
    return flavorsDiv;
}
function prepareRatingDiv(inMenuitem){
    let ratingDiv = '';
    let userLoggedIn = $('#userId').val();
    if(inMenuitem && inMenuitem.ratedByUserId && inMenuitem.ratedByUserId==userLoggedIn){
      let myrate = true;
      let myrateId = 'home-irated-'+inMenuitem.menuitem.menuItemId;
      if(inMenuitem.menuitem.upcRating && inMenuitem.menuitem.upcRating > 0){
        ratingDiv =`${loadRate(inMenuitem.menuitem.upcRating, myrate, myrateId)}`;
      }
      else if(inMenuitem.menuitem.skuRating && inMenuitem.menuitem.skuRating > 0){
        ratingDiv =`${loadRate(inMenuitem.menuitem.skuRating, myrate, myrateId)}`;
      }
      else if(inMenuitem.menuitem.avgRating && inMenuitem.menuitem.avgRating > 0){
        ratingDiv = `${loadRate(inMenuitem.menuitem.avgRating, myrate, myrateId)}`;
      }
      else if(inMenuitem.starRating && inMenuitem.starRating > 0){
        ratingDiv = `${loadRate(inMenuitem.starRating, myrate, myrateId)}`;
      }
    }else{
      let myrate = false;
      let otherRateId = 'home-otherrated-'+inMenuitem.menuitem.menuItemId;
      if(inMenuitem.menuitem.upcRating && inMenuitem.menuitem.upcRating > 0){
        ratingDiv = `${loadRate(inMenuitem.menuitem.upcRating, myrate, otherRateId)}`;
      }
      else if(inMenuitem.menuitem.skuRating && inMenuitem.menuitem.skuRating > 0){
        ratingDiv =  `${loadRate(inMenuitem.menuitem.skuRating, myrate, otherRateId)}`;
      }
      else if(inMenuitem.menuitem.avgRating && inMenuitem.menuitem.avgRating > 0){
        ratingDiv = `${loadRate(inMenuitem.menuitem.avgRating, myrate, otherRateId)}`;
      }
      else if(inMenuitem.starRating && inMenuitem.starRating > 0){
        ratingDiv = `${loadRate(inMenuitem.starRating, myrate, otherRateId)}`;
      }
    }
    return ratingDiv;
}

/* get discounts in an ajax call for menuitems and venues */
function get_search_discounts(primaryType) {
  let itemIds=[]
  let userId = $('#userId').val();
  console.log("primaryType=111==",primaryType)
  if(primaryType=="restaurant" || primaryType==null){

    var findElement = ".search-venue"
  }else{
    var findElement = ".search-menuitem";
  }
  $(findElement).each(function (index, element) {
    let itemId=$(element).val();
    let venueId=$(element).attr("data-venueId");
    if(itemId && venueId) {
      if(!itemIds[venueId]) itemIds[venueId]=[]
      itemIds[venueId].push(itemId);
    }
 });
  for(let key in itemIds){
    let itemIdsPerVenue=itemIds[key]
   let data= {}
   if(itemIdsPerVenue && itemIdsPerVenue.length > 0  && key !="diff"){
     if(primaryType=="restaurant"){
        data={venueId:key, getMaxDiscount: true, menuItemIds:null, userId: userId,pagetype:"homepage"} //menuitems must be sent as empty array, else it gets all menuitems discounts for that venue
     } else {
        data={venueId:key, menuItemIds: itemIdsPerVenue, userId: userId,pagetype:"homepage"}
     }
     $.ajax({
        url: '/getItemsDiscounts',
        method: 'POST',
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        dataType: 'json',
        error: ()=> {
          console.log("error when getting discounts for itemIds...", key, itemIdsPerVenue);
        }
      })
      .then( (results) => {
        if(results && results.data){
          if(Array.isArray(results.data)){
            results.data.forEach((item)=>{
              if(item && item.maximumDiscount > 0){
                item.venueId = key
                render_discounts(item, primaryType);
              }
            });
          } else {
            if(results.data.maximumDiscount > 0){
              results.data.venueId = key
              render_discounts(results.data, primaryType);
            }
          }
        } //data length > 0
      }) //ajax end
    } // itemids for venue if end
  } //end for of itemIds
}

//prepare the discount div after getting data and append to item
function render_discounts(discount, primaryType){
  console.log("discount===",discount)
     let flipdiv = '';
      let maxDiscount = 0;
      if(discount){
        maxDiscount = discount.maximumDiscount;
         if(Number(maxDiscount) > 0){
          if(!primaryType || primaryType=="restaurant"){
            let venueId = discount.venueId;
            let venueSlug = $("#venue-slug-"+venueId).val();
 	    flipdiv =`
		<a   class="icon flip-action "  data-discount=${JSON.stringify(discount.eligibleDiscounts)} data-toggle="modal" data-target="#discount_flip_div">
		    <span class="earn">Earn up to ${maxDiscount}% credit!</span>
		    <span class="font-10"></span>
		  <input type="hidden" id="venueSlug" value="${ venueSlug }">
		  </a>`;
             $(".discountdiv_"+venueId).html(flipdiv);
             $("#toprated_discountdiv_"+venueId).html(flipdiv);
	   } else { //display for menuitems
             let menuItemId=discount.menuItemId;
             let venueId=$("#search-menuitem-"+menuItemId).attr("data-venueId");
             let venueSlug = $("#venue-slug-"+venueId).val();
             let menuitemSlug = $("#menuitem-slug-"+menuItemId).val();
             let price = $("#menuitem-price-"+menuItemId).val();
             flipdiv =`
            <a class="icon flip-action" data-val=true data-discount=${JSON.stringify(discount.eligibleDiscounts)}
            data-price=${price && Number(price)>0 ? Number(price).toFixed(2) : '0.00' } data-toggle="modal" data-target="#discount_flip_div" >
            <span class="earn">Earn up to ${maxDiscount} % credit!</span>
            <br><span class="font-10"></span>
                 <input type="hidden" id="menuItemSlug" value="${menuitemSlug}" />
            <input type="hidden" id="venueSlug" value="${ venueSlug }">
            </a>`;
             $(".discountdiv_"+menuItemId).html(flipdiv);
             $("#toprated_discountdiv_"+menuItemId).html(flipdiv);
             $("#ratedmenuitem_discountdiv_"+menuItemId).html(flipdiv);
             $(".discountdiv_"+menuItemId).children().removeAttr("data-toggle");

          }
        }
      }
}

function prepareHiddenDataDiv(inMenuitem, venueSlug){
    let hiddenDataDiv = `
    <div class="hidden venueData">
          <input id="venue-id" class="search-venue" type="hidden" data-venueId="${inMenuitem.venueId }" value="${inMenuitem.venueId }">
          <input id="venue-phone" type="hidden" value="${ inMenuitem.contact ? inMenuitem.contact.phone : '' }">
          <input id="venue-name" type="hidden" value="${ inMenuitem.name ? inMenuitem.name : '' }">

          <input id="venue-slug-${inMenuitem.venueId}" type="hidden" value="${ venueSlug }">
          <input id="venue-location" type="hidden" value="${ inMenuitem.location ? inMenuitem.location.formattedAddress : '' }">
          <input id="venue-distance" type="hidden" value="${ inMenuitem.location.distance }">
          <input id="venue-lat" type="hidden" value="${ inMenuitem.location ? inMenuitem.location.lat : '' }">
          <input id="venue-lng" type="hidden" value="${ inMenuitem.location ? inMenuitem.location.lng : '' }">
          <input id="venue-rating" type="hidden" value="${ inMenuitem.googleRating ? inMenuitem.googleRating.rating: '' }">
          <input id="venue-rating-updated-on" type="hidden" value="${ inMenuitem.googleRating ? inMenuitem.googleRating.updated: '' }">
          <input id="venue-user-ratings-total" type="hidden" value="${ inMenuitem.googleRating ? inMenuitem.googleRating.userRatingsTotal: '' }">
          `
          if(inMenuitem.menuitem && inMenuitem.menuitem.menuItemId){
      hiddenDataDiv += `
          <input id="search-menuitem-${inMenuitem.menuitem.menuItemId}" class="search-menuitem" type="hidden" data-venueId="${inMenuitem.venueId }" value="${inMenuitem.menuitem.menuItemId}">
          <input id="menuitem-price-${inMenuitem.menuitem.menuItemId}" type="hidden" value="${ inMenuitem.menuitem && inMenuitem.menuitem.price ? inMenuitem.menuitem.price: '0.00' }">
          <input id="menuitem-slug-${inMenuitem.menuitem.menuItemId}" type="hidden" value="${ inMenuitem.menuitem && inMenuitem.menuitem.slug ? inMenuitem.menuitem.slug: inMenuitem.menuitem.menuItemId }">
          `
         }
      hiddenDataDiv += `</div>`
      return hiddenDataDiv
}


//render carousel for top rated restaurant, one at a time
function prepareUIHomePageTopRatedVenues(pagenumber, homepagediv){
  let venueContainerDiv='';
  venueContainerDiv +=`<div class="col-md-12"><div class="venueslick">`;
   // let venues=default_venues;
   let venues=default_venues.slice((pagenumber-1)*1);
    let venuesDetails=default_venuesDetails;
    venuesDetails=default_venuesDetails;
    //let venue = default_venues[pagenumber-1];
    let index = 0;
    let userLoggedIn = $('#userId').val();

    let isBookMark = false;
    let isLiked=false;
    venues.forEach((venue,index) => {
    if(index<=1){

    let venueHtml = '';
    if(userLoggedIn){
      if(venue){
        const findBookMark = wishlistData ? wishlistData.some((element) => element.venueId === venue.venueId && element.userID ===userLoggedIn) : false;
        if(findBookMark)
        isBookMark = true;
        const findLike= dislikesData ? dislikesData.some((element) => element.venueId === venue.venueId  && element.userID ===userLoggedIn) : false;
        if(findLike )
        isLiked = true;
      }
    }
    let venueSlug = venue && venue.slug ? venue.slug :venue.id;
    let pricetier = venue.venue && venue.venue.price ? venue.venue.price.tier : 1;
    let venueDetailsForVenue = null;
    let deliveryTime = venue.deliveryMaxTime || '';
    let deliveryFeesDiv = "";
    if(venuesDetails && venuesDetails.length>0) {
        let venueDetailsForMenuArr = venuesDetails.filter(x=>x.venueId == venue.id || x.venueId == venue.venueId);
        if(venueDetailsForMenuArr.length > 0){
           venueDetailsForVenue = venueDetailsForMenuArr[0]; //this is being used below since venue may not have details if source is rating
           if(venueDetailsForVenue){
             if(venueDetailsForVenue.slug ){
              venueSlug = venueDetailsForVenue.slug;
             }
             if(venueDetailsForVenue.pricetier ){
              pricetier = venueDetailsForVenue.pricetier;
             }
             if(venueDetailsForVenue.deliveryMaxTime ){
              deliveryTime = venueDetailsForVenue.deliveryMaxTime;
             }
             deliveryFeesDiv=prepareDeliveryFeesDiv(venueDetailsForVenue);
           }
          else{
           venueSlug = venue.id || venue.venueId
          }
        }
        else{
//          console.log("ODDDD, no venue details for this venueid...",venue.venueId);
           venueSlug = venue.venueId
        }
    }
    else{
//      console.log("ODDDD, no venue details for any venueid...",venue.id);
     venueSlug = venue.id
    }
    let venueUrl= "/restaurant/"+venueSlug;
    if(pricetier<=1) pricetier="$"
    else if(pricetier==2) pricetier="$$"
    else if(pricetier==3) pricetier="$$$"
    else if(pricetier==4) pricetier="$$$$"
    else pricetier="$$$$$"
    //not being called now since this used discounts for venues from controller, and now we are getting them realtime even for venues
//    let flipdiv= prepareDiscountDivVenue(venueDetailsForVenue, venueSlug,'toprated');
    let hiddenDataDiv= prepareHiddenDataDiv(venue, venueSlug);

    let restAddress = '';
    if(venue.location && venue.location.formattedAddress){
    restAddress =venue.location.formattedAddress.toString();
    if(restAddress.length>70){
    restAddress = `<div class="item-description addressToDirection font-12" name="venue-address" data-toggle="tooltip" data-placement="bottom" title="${ venue.location.formattedAddress}" data-address="${venue.location.formattedAddress.toString()}"> ${venue.location.formattedAddress.toString().substring(0,68)+'....'}  </div>`;
    }else{
    restAddress = `<div class="item-description addressToDirection font-12" name="venue-address" data-address="${venue.location.formattedAddress.toString()}"> ${venue.location.formattedAddress}  </div>`;
    }
  }
  $('[data-toggle="tooltip"]').tooltip({
        trigger : 'hover'
    });
    let rated_menuitems_div='';
    if((venue.ratedMenuItems && venue.ratedMenuItems.length>0) ){
      rated_menuitems_div+=`<div class="carausalmenuitem" >`;
      let price='';
      let itemDesc = '';
      for(let i=0;i<venue.ratedMenuItems.length;i++) {
	    if(i<3){
        let ratedMenuItem = venue.ratedMenuItems[i];
        if(ratedMenuItem.price && ratedMenuItem.price.trim()!='Unknown'){
          price= '$ '+ratedMenuItem.price
        }
        let menuratingDiv='';
        console.log("ratedMenuItem====", ratedMenuItem)
        if(ratedMenuItem && ratedMenuItem.userId){
          let myrate = true;
          let myrateId = 'home-irated-'+ratedMenuItem.menuItemId;

          if(ratedMenuItem.starRating && ratedMenuItem.starRating > 0){
            menuratingDiv = `${loadRate(ratedMenuItem.starRating, myrate, myrateId)}`;
          }
        }else{
          let myrate = false;
          let otherRateId = 'home-otherrated-'+ratedMenuItem.menuItemId;
          if(ratedMenuItem.starRating && ratedMenuItem.starRating > 0){
            menuratingDiv = `${loadRate(ratedMenuItem.starRating, myrate, otherRateId)}`;
          }
        }
         let reviewCount ='0';
         if(ratedMenuItem && ratedMenuItem.reviewsCount && ratedMenuItem.reviewsCount > 0){
            reviewCount = `<div id="item-review-count"  class="rev" style="margin-top:-2px !important;">(${ratedMenuItem.reviewsCount})`
            reviewCount+= `</div>`
         }
        let displaymenuitemName='';
        let menuitemNameHalf='';
        let ratedMenuItemName= ratedMenuItem.menuItemName.replace(/&apos..../g, "\'").replace(/&Apos..../g, "\'")
        if(ratedMenuItemName.length>14){
              menuitemNameHalf = ratedMenuItemName.substring(0,13)+'....';
              displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItem=${ratedMenuItem.menuItemSlug}&menuItemLink=${ratedMenuItem.menuItemId}" data-toggle="tooltip" data-placement="bottom" title="${ratedMenuItem.menuItemName}" class="black font-12 col-md-4 pl-0 item-name ">
                    ${menuitemNameHalf}
                </a>`;
         } else {
                displaymenuitemName = ` <a href="/restaurant/${venueSlug}?menuItem=${ratedMenuItem.menuItemSlug}&menuItemLink=${ratedMenuItem.menuItemId}" class="black font-12 col-md-4 pl-0 item-name ">
                                    ${ratedMenuItemName}
                                </a>`;
        }
        let adminflavormaxValue=$("#flavorMatchPercentForColorGreen").val();
        let adminflavorminValue=$("#flavorMatchPercentForColorRed").val();
        itemDesc=ratedMenuItem.description
        let itemDescHover=ratedMenuItem.description;
        let itemDescCutoff =  itemDesc.length > 31 ? itemDesc.substring(0,30)+'....' : itemDesc;
        const searchTermRegex = new RegExp(`${ratedMenuItem.description}`,'gi');

        if(itemDesc.length > 31){
                itemDescCutoff= itemDescCutoff.replace(searchTermRegex, `<span class="search-term-highlight">${ratedMenuItem.description}</span>`);
                itemDesc = `<p class="item-description" data-toggle="tooltip" data-placement="bottom" title="${itemDescHover }" >
                  ${ itemDescCutoff }
                </p>`;
        }else{
          itemDesc= itemDesc.replace(searchTermRegex, `<span class="search-term-highlight">${ratedMenuItem.description}</span>`);
              itemDesc = `<p class="item-description" >
                ${ itemDesc }
                </p>`;
            }
       let flavorMatch=null;
       if(Number(ratedMenuItem.flavorMatch)>=0){
         flavorMatch= Number(ratedMenuItem.flavorMatch);
       }
       let hiddenDataDivRatedMenuItem = `
          <input id="ratedmenuitem-search-menuitem-${ratedMenuItem.menuItemId}" class="search-menuitem" type="hidden" data-venueId="${venue.venueId }" value="${ratedMenuItem.menuItemId}">
          <input id="ratedmenuitem-menuitem-price-${ratedMenuItem.menuItemId}" type="hidden" value="${price}">`

       rated_menuitems_div+=`
       ${hiddenDataDivRatedMenuItem}
       <div class="col-md-12 col-12 mt-2 px-2 px-md-3" style="box-shadow: 0 2px 8px  #F1F1F1;">
       <div class="row" style="margin-right:-30px !important; margin-left:-30px !important">

        <div class="col-md-5 col-5">
          <div class="image-lazy-load " >
              <img class="restimageeclipse itemImage" id="${ratedMenuItem.menuItemId}"
                     src="/images/tempfoodnotext.png" img-type="Menu Item" venue-id="${venue.venueId}">
            <div id=ratedmenuitem_discountdiv_${ratedMenuItem.menuItemId}" style="padding:0"></div>
          </div>
        </div>
          <div class="col-md-7 col-6 ml-1 ml-md-0 px-2 px-md-3">${displaymenuitemName} <span class="black mt-1 font-12" style="float:right"><b> ${price}</b></span>


            <div class="ratingdiv mt-md-3" href="javascript:void(0)" class="hvr-underline-from-center star-rating mt-1"  style="cursor: pointer;width: fit-content;" >
               ${menuratingDiv}
               ${ratedMenuItem && ratedMenuItem.reviewsCount && ratedMenuItem.reviewsCount > 0 ? reviewCount: ''}
            </div>


            <span class="font-12 mt-md-2 mobiledesciption">
            ${itemDesc}
            </span>

            <a class="mobilebuybutton mt-1">
               <button class="addbtn-cart mt-0" id="home-buy-button-${ratedMenuItem.menuItemId }" onclick="addItemToCart('${venue.venueId}','${ratedMenuItem.menuItemId }','${venueSlug }')" >  <i class="fa fa-shopping-cart " aria-hidden="true"></i> Add</button>
            </a>
             <a class="desktopbuybutton mt-3">
             <button class="addbtn-cart mt-0" id="home-buy-button-${ratedMenuItem.menuItemId }" onclick="addItemToCart('${venue.venueId}','${ratedMenuItem.menuItemId }','${venueSlug }')" >  <i class="fa fa-shopping-cart " aria-hidden="true"></i> Add</button>
            </a>
          </div>
         </div>
        </div>

        `;
      }
    }
    }

    let dietPrefMatchDiv='';
    let averageRatingDiv=''
    if(venue.averageRatingMenuItems && venue.averageRatingMenuItems > 0){
         averageRatingDiv = `${loadRate(venue.averageRatingMenuItems, false, null)}`;
     }

    if(userLoggedIn  ){
      if(venue.dietPrefMatchMenuItemsCount>0){
        dietPrefMatchDiv=`
       <div class="mt-3 font-11 col-12 pl-md-0">${ venue.dietPrefMatchMenuItemsCount } items may/do meet my dietary prefs</div>

    `
      }
	   
    }else{
        dietPrefMatchDiv= `<div class="col-12 dietary_signup mt-3 text-center"><div class="pt-md-3 pt-1 pl-md-0 pr-md-0 pl-4 pr-4"><a href="/users/register" class="font-16 home-set-prefs-login darkgrey"><span class="text-underlined green">Sign up </span> </a></div></div>
       `;
      
    }
    let displaymenuitemName='';
    let menuitemNameHalf='';
    let ratedMenuItemName= venue.name.replace(/&apos..../g, "\'").replace(/&Apos..../g, "\'")
     if($(window).width() < 724){

        // if(ratedMenuItemName.length>17){
        //   menuitemNameHalf = ratedMenuItemName.substring(0,17)+'....';
        //   displaymenuitemName = ` <a href="/restaurant/${venueSlug}" data-toggle="tooltip" data-placement="bottom" title="${venue.name}" class="black font-18 col-md-4 pl-0 item-name ">
        //         ${menuitemNameHalf}
        //     </a>`;
        //  } else {
                displaymenuitemName = ` <a href="/restaurant/${venueSlug}" class="black font-18 col-md-4 pl-0 item-name " style="white-space:unset">
                                    ${ratedMenuItemName}
                                </a>`;
        // }

    }else{

        if(ratedMenuItemName.length>22){
          menuitemNameHalf = ratedMenuItemName.substring(0,22)+'...';
          displaymenuitemName = ` <a href="/restaurant/${venueSlug}" data-toggle="tooltip" data-placement="bottom" title="${venue.name}" class="black font-18 col-md-4 pl-0 item-name ">
                ${menuitemNameHalf}
            </a>`;
         } else {
                displaymenuitemName = ` <a href="/restaurant/${venueSlug}" class="black font-18 col-md-4 pl-0 item-name ">
                                    ${ratedMenuItemName}
                                </a>`;
        }

    }


    venueHtml += `
        <div class="venuslide" >
          <div  class="results-col" >
            <div>
              <div class="face front">
              <div class="row yelpresults-contentcarousel yelpresults-containercarousel${pagenumber}">


                <div  class="col-md-7 mt-4 ml-md-5  ml-2 image-lazy-load go-to-specified-restaurant carouselflip pr-md-0" style="padding-left:0px">
                  <a href="/restaurant/${venueSlug}" class="orange item-name pr-0">
                      <img class="craousalitemImage itemImage" id="${venue.venueId}"  venue-id="${venue.venueId}"
                      src="/images/tempfoodnotext.png" img-type="Restaurant">
                  </a>
            <div id="toprated_discountdiv_${venue.venueId}"></div>
                  <div class="form-row carausallike-wishlist-container topwishlist">
                      <a href="javascript:void(0)" class="whistlist hide"><i style="margin-right: -10px !important;" class="over-image-icons float-right ${ isBookMark ? 'fas bookmark-fas' : 'far bookmark-far'} fa-bookmark" style="cursor: pointer;"
                         data-venueId=${venue.venueId} data-venueId=${venue.venueId} ></i></a>
                  </div>

                  <div  class="form-row carausallike-wishlist-container bottomwishlist">
                      <a><i  onClick="likeFunc(this)"  class="over-image-icons float-left fa-w-16 fa-flip-horizontal fa-flip-vertical ${isLiked? 'fas likewishlist-fas': 'far likewishlist-far' } fa-thumbs-up hide" aria-hidden="true" data-venueid="${venue.venueId}" data-user-id="${userLoggedIn}"></i></a>
                  </div>
                  <div class="row ">
                  <div class="col-md-8 item-details mt-3 mt-md-4" >
                   <div class="row recommend-local-results">
                      <div class="col-md-5 ml-md-3" style="float:left;">
                      ${displaymenuitemName}

                      </div>
                      <div class="col-md-6 col-12 yelp-rating-stars pr-md-0 pl-md-1 mt-2">
                      </div>
                      <input type="hidden" value="${venue.name}" id="venues-name">
                      ${hiddenDataDiv}

                    </div>
                    <div class="row">
                      <div class="col-md-6 ml-md-3 ml-0" style="float:left;">
                      ${restAddress}
                      </div>
                      <div class="col-md-5 pl-md-1" style="float:right;">
                        <span style="color:#4A4A4A;" class="font-10">
                        <img src="/images/clock.png" style="display:unset;width:unset" /> &nbsp;Time&nbsp;&nbsp; <img src="/images/time.png"  style="display:unset;width:unset" />&nbsp; ${deliveryTime}Min&nbsp;&nbsp;
                        <img src="/images/truck.png" style="display:unset;width:unset" />&nbsp;${deliveryFeesDiv}</span>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 home-avg-ratings" style="float:right;border-left: 1px solid #EFEFEF">
                   ${dietPrefMatchDiv}
                  </div>

                </div>
                <div class="row col-md-12 col-12 mr-0 mt-md-1 mt-3 pr-md-0">
                     <div class="col-12 col-md-8 " >
                       <a href="${venueUrl}"><button class="addbtn-cart mt-0" >See Menu</button></a>
                    </div>
                </div>

                </div>
                <div class="col-md-4">
                  <div class="mt-4">
                  <div class="row  mr-4">
                    <div class="col-md-12 col-12 "><b class="font-14 black">Top rated & rewards at ${venue.name}</b></div>

                 </div>

                  ${rated_menuitems_div}
                  </div>
                </div>
                </div>
                </div>
               </div>
            </div>
          </div>
        </div>
     `;

    venueContainerDiv += venueHtml;
    }
    } );
    venueContainerDiv +=` </div></div>`

    let venueContainer = $(homepagediv);
    if(venueContainer){

      venueContainer.html(venueContainerDiv);
      $('.venueslick').slick({
        dots: false,
        speed: 300,
        swipe: true,
        infinite: false,
        slidesToShow: 1,
        fade: true,
        cssEase: 'linear',
        adaptiveHeight: true,
        prevArrow:"<button id='menusplash-prev' type='button' class='slick-prev pull-left' style='left:-4%'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
         nextArrow:"<button id='menusplash-next' type='button' class='slick-next pull-right' style='right:-4%'><i class='fa fa-angle-right' aria-hidden='true' ></i></button>"
      });
      if(pagenumber==1){
        $('#menusplash-prev').addClass('hidden')
      }
      if((default_venues.length)-1==pagenumber){
        $('#menusplash-next').addClass('hidden')
      }
     } else {
      console.log("venueContainer div not found...",homepagediv);
    }
    if(isMobile || $(window).width() < 424){
      $(".desktopbuybutton").css("display","none");
      $(".mobilebuybutton").css("display","block");
      $(".mobiledesciption").css("display","block");
    }else{
      $(".desktopbuybutton").css("display","block");
      $(".mobilebuybutton").css("display","none");
      $(".mobiledesciption").css("display","none");
    }

} //end of prepareUIHomePageTopRatedVenues

  /*prepareUIHomePageVenues data for home page */
  function prepareUIHomePageVenues(pagenumber, homepagediv){
    let venueContainerDiv='';
    let venues=default_venues;
    let venuesDetails=default_venuesDetails;
    if(homepagediv=='#recommended-by-locals'){
     venues=local_venues.slice((pagenumber-1)*4);
     venuesDetails=local_venuesDetails;
      $('#recommended-by-localsspan').html('');
      if(venues.length>4){
         $('#recommended-by-localsspan').append(`<a href="javascript:void(0)" id="recommended-by-localsback" style="visibility:hidden; " data-value="venues" ><i class="fas fa-angle-left pr-2 font-18"></i>Go back &nbsp;  &nbsp; |  &nbsp;  &nbsp; </a>`);
         $('#recommended-by-localsspan').append(`<a href="javascript:void(0)" id="recommended-by-localsview"  data-value="venues" >View More <i class="fas fa-angle-right font-18 pl-2"></i></a>`);
      } else if (pagenumberL > 1 ) {
        $('#recommended-by-localsspan').append(`<a href="javascript:void(0)" id="recommended-by-localsview" style="float: right; " data-value="venues" >View More <i class="fas fa-angle-right"></i></a>`);
        pagenumberL = 0;
      }
    }
    else if(homepagediv=='#recommended_friends'){
     venues=friends_venues.slice((pagenumber-1)*4);
     venuesDetails=friends_venuesDetails;
      $('#recommended_friendsspan').html('');
      if(venues.length>4){
        $('#recommended_friendsspan').append(`<a href="javascript:void(0)" id="recommended_friendsback" style="visibility:hidden; " data-value="venues" ><i class="fas fa-angle-left pr-2 font-18"></i>Go back &nbsp;  &nbsp; |  &nbsp;  &nbsp; </a>`);

        $('#recommended_friendsspan').append(`<a href="javascript:void(0)" id="recommended_friendsviews"  data-value="venues" >View More <i class="fas fa-angle-right"></i></a>`);
      } else if (pagenumberF > 1 ) {
        $('#recommended_friendsspan').append(`<a href="javascript:void(0)" id="recommended_friendsviews" style="float: right;" data-value="venues" >View Again <i class="fas fa-angle-right"></i></a>`);
        pagenumberF  = 0;
      }
    }
    else if(homepagediv=='#nearby-happy-hour'){
     venues=nearby_venues.slice((pagenumber-1)*4);
     venuesDetails=nearby_venuesDetails;
      $('#nearby-happy-hourspan').html('')
      if(venues.length>4){
         $('#nearby-happy-hourspan').append(`<a href="javascript:void(0)" id="nearby-happy-hourback" style="visibility:hidden; " data-value="venues" ><i class="fas fa-angle-left pr-2 font-18"></i>Go back &nbsp;  &nbsp; |  &nbsp;  &nbsp; </a>`);

        $('#nearby-happy-hourspan').append(`<a href="javascript:void(0)" id="nearby-happy-hourview" style="float: right; " data-value="venues">View More <i class="fas fa-angle-right"></i></a>`);
      } else if (pagenumberN > 1 ) {
        $('#nearby-happy-hourspan').append(`<a href="javascript:void(0)" id="nearby-happy-hourview" style="float: right; " data-value="venues">View Again <i class="fas fa-angle-right"></i></a>`);
        pagenumberN = 0;
      }
    }
    else if(homepagediv=='#dietpref-venues'){
     venues=default_dietpref_venues.slice((pagenumber-1)*4);
     venuesDetails=default_venuesDetails;
      $('#dietpref-venuesspan').html('')
      if(venues.length>4){
        $('#dietpref-venuesspan').append(`<a href="javascript:void(0)" id="dietpref-venuesback" style="visibility:hidden; " data-value="venues" ><i class="fas fa-angle-left pr-2 font-18"></i>Go back &nbsp;  &nbsp; |  &nbsp;  &nbsp; </a>`);

        $('#dietpref-venuesspan').html(`<a href="javascript:void(0)" id="dietpref-venuesview" style="float: right; " data-value="venues">View More <i class="fas fa-angle-right"></i></a>`);
      } else if (pagenumberD > 1 ) {
        $('#dietpref-venuesspan').html(`<a href="javascript:void(0)" id="dietpref-venuesview" style="float: right; " data-value="venues">View Again <i class="fas fa-angle-right"></i></a>`);
        pagenumberD = 0;
      }
    }
    if(venues && venues.length>0){
      venues.forEach((venue, index) => {
        if(index<4){
          let venueHtml = renderSearchVenue(venue, venuesDetails, index);
          venueHtml += `${index + 1 == venues.length ? '' : '' }`;
          venueContainerDiv += venueHtml;
        }
      }); //end of venue loop
    }
    //add to div
    let venueContainer = $(homepagediv);
    if(venueContainer){
      venueContainer.html(venueContainerDiv);
     } else {
     // console.log("venueContainer div not found...",homepagediv);
    }
  } //enf od prepareUIHomePageVenues
  showImages();
    $('.go-to-specified-restaurant').on('click', function(event){
      event.preventDefault();
      let link = $(this).find('a').attr('href')
      location.assign(link)
    })


function renderSearchVenue(venue, venuesDetails,  index){
  let userLoggedIn = $('#userId').val();
    let venueHtml = '';
    let isBookMark = false;
    let isLiked=false;
    if(userLoggedIn){
      if(venue){
        const findBookMark = wishlistData ? wishlistData.some((element) => element.venueId === venue.venueId && element.userID ===userLoggedIn) : false;
        if(findBookMark)
        isBookMark = true;
        const findLike= dislikesData ? dislikesData.some((element) => element.venueId === venue.venueId  && element.userID ===userLoggedIn) : false;
        if(findLike )
        isLiked = true;
    }
    }
    let venueSlug = venue.slug ?  venue.slug: venue.id;
    let venueDetailsForVenue = null;
    let pricetier = venue.venue && venue.venue.price ? venue.venue.price.tier : 1;
    let deliveryTime = venue.deliveryMaxTime || ''
    let deliveryFeesDiv = ""
    if(venuesDetails && venuesDetails.length>0) {
        let venueDetailsForMenuArr = venuesDetails.filter(x=>x.venueId == venue.id || x.venueId == venue.venueId);
        if(venueDetailsForMenuArr.length > 0){
           venueDetailsForVenue = venueDetailsForMenuArr[0]; //this is being used below since venue may not have details if source is rating
           venueSlug = venue.id || venue.venueId
           if(venueDetailsForVenue){
             if(venueDetailsForVenue.pricetier ){
                pricetier = venueDetailsForVenue.pricetier;
             }
             if(venueDetailsForVenue.slug ){
              venueSlug = venueDetailsForVenue.slug;
             }
             if(venueDetailsForVenue.deliveryMaxTime ){
              deliveryTime = venueDetailsForVenue.deliveryMaxTime;
             }
             deliveryFeesDiv=prepareDeliveryFeesDiv(venueDetailsForVenue);
           }
        }
        else{
//          console.log("ODDDD, no venue details for this venueid...",venue.venueId);
           venueSlug = venue.venueId
        }
    }
    else{
//      console.log("ODDDD, no venue details for any venueid...",venue.id);
     venueSlug = venue.id
    }
   if(pricetier<=1) pricetier="$"
    else if(pricetier==2) pricetier="$$"
    else if(pricetier==3) pricetier="$$$"
    else if(pricetier==4) pricetier="$$$$"
    else pricetier="$$$$$"
    //not being called now since this used discounts for venues from controller, and now we are getting them realtime even for venues
//  let flipdiv= prepareDiscountDivVenue(venueDetailsForVenue, venueSlug);
  let hiddenDataDiv = prepareHiddenDataDiv(venue, venueSlug);
	let restAddress = '';
	if(venue.location && venue.location.formattedAddress){
		restAddress =venue.location.formattedAddress.toString();
		restAddress = `<div class="item-description addressToDirection font-12" name="venue-address" data-address="${venue.location.formattedAddress.toString()}"> ${venue.location.formattedAddress}  </div>`;
		
	}
	$('[data-toggle="tooltip"]').tooltip({
        trigger : 'hover'
    });
    if(window.location.href.indexOf("basicsearch") > -1){
     $(".topheader").css("height",'0px');
    venueHtml =
    ` <div class="menucard">`;
    }else{
      venueHtml =
    ` <div class="menucard">`;
    }
    

       venueHtml += `


          <div  class="results-col resturantmenu flip2" style="${!userLoggedIn? 'height:28rem;' :'' }">
            <div class="card yelpresults-content yelpresults-container${index}">
              <div class="face front">
                <div class="mune-img  homepageimage image-lazy-load go-to-specified-restaurant">
                   <a href="/restaurant/${venueSlug}" class="orange">
                      <div class="aspect-ratio-box">
                        <div class="aspect-ratio-box-inside">
                          <img id="${venue.venueId}" img-type="Restaurant"  venue-id="${venue.venueId}" style="" class="itemImage" src="/images/tempfoodnotext.png">
                        </div>
                      </div>
                  </a>
									<div class="form-row like-wishlist-container rest-like-wishlist-container " style="height:56px">
										<div class="col-md-3 col-3"></a>
										</div>
										<div class="col-md-8 col-8">
										</div>
										<div class="col-md-1 col-1 mt-2"><a href="#"><i class="over-image-icons ${ isBookMark ? 'fas bookmark-fas' : 'far bookmark-far'} fa-bookmark" style="cursor: pointer;"  data-venueId=${venue.venueId}></i></a>
										</div>
                  </div>
                </div>
            <div id="discountdiv_${venue.venueId}" class="discountdiv_${venue.venueId}"></div>
                <div class="food-details mb-2 mt-md-0">
                  <div class="row ">
                   <div class="ellipsis col-md-10 col-10 pl-3">
                      <a href="/restaurant/${venueSlug}" id="venue-name" class=" font-18 black">

                          ${venue.name}                 </a>

                      </div>
                      <div class="col-md-2 pl-md-1 pr-md-3 col-2">
                         <span class="font-16 float-right">${ pricetier }</span>
                     </div>

                  </div>
				        <div class="row">
						        <div class="col-md-12 col-12 pl-3" >${restAddress}</div>

						        <a href="tel: +1 ${venue.contact && venue.contact.formattedPhone  ? venue.contact.formattedPhone : '' }" name="venue-phone"
						        class="venue-phone font-15 col-12"> ${venue.contact && venue.contact.formattedPhone  ? venue.contact.formattedPhone : '' }
						        </a>
                    <div class="col-md-12 col-12 mt-md-3 mt-4 delivery-time-icons pl-3">
                        <span style="color:#4A4A4A;" class="font-11">
                        <img src="/images/clock.png" style="display:unset;width:unset"  /> &nbsp;Time&nbsp;&nbsp; <img src="/images/time.png" style="display:unset;width:unset" />&nbsp; ${deliveryTime}Mins&nbsp;&nbsp;
                        <img src="/images/truck.png" style="display:unset;width:unset"  />&nbsp;${deliveryFeesDiv}</span>

                    </div>
				        </div>
                  <div class="row mb-2 home-restlocations-miles">
                      <div class="col-md-12 pl-3"><span class="font-12"><i class="fa fa-map-marker grey font-10"></i>${ venue.location.distance} miles away</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-12 col-12 yelp-rating-stars pr-0 pl-3">
                      </div>
                  </div>
                  <div class="form-row col-md-12 col-12  recommend-see-menu-btn food-flavor homepage-prefs-div ">
                      <a href="/restaurant/${venueSlug}" class="w-100"><button class="addbtn-cart mt-0" >See Menu</button></a>
                  </div>
              </div>
              ${ hiddenDataDiv}
            </div>
          </div>
        </div>
      </div>
    `;
  return venueHtml;

}

//this is being used for showing venue discounts; still using the discount from backend
/* 210717 not being used now since realtime discounts even for venues
tbdeleted
function prepareDiscountDivVenue(venueDetailsForVenue, venueSlug,toprated){
    let flipdiv='';
    if(venueDetailsForVenue && venueDetailsForVenue.discounts ){
      let discount = null;
      let discountjson = JSON.parse(JSON.stringify(venueDetailsForVenue)).discounts;
      if(discountjson && discountjson.length > 0){
        discount = discountjson[0];
      }
      else{
//                     console.log("restaurant discount is an object...",discountjson);
         discount = discountjson;
      }
      let maxDiscount = discount.maximumDiscount;
      if(Number(maxDiscount) > 0){
//                      console.log("restaurants discount...",discount,resultsVenues[j].slug);
        if(toprated=='toprated'){
          flipdiv =`
        <a data-target="#discount_flip_div" data-toggle="modal"  class="icon flip-action "  data-discount=${JSON.stringify(discount.eligibleDiscounts)}>
            <span class="earn">Earn up to ${maxDiscount}% credit!</span>
            <span class="font-10"></span>
          <input type="hidden" id="venueSlug" value="${ venueSlug }">
          </a>`;
        }else{
          flipdiv =`
        <a   class="icon flip-action "  data-discount=${JSON.stringify(discount.eligibleDiscounts)}>
            <span class="earn">Earn up to ${maxDiscount}% credit!</span>
            <span class="font-10"></span>
          <input type="hidden" id="venueSlug" value="${ venueSlug }">
          </a>`;
        }

      }
    }
   return flipdiv;
}
*/

    $('#room_lat').on('change', function () {
        const lat = $('#room_lat').val();
        const long = $('#room_long').val();
        let res = Promise.resolve($.post('/homePageDoSearchPrivateRoom', { lat: lat, long: long }, function (venues) {
            venues.forEach((venue, index) => {
            let venueSlug = venue.slug;
            if(!venueSlug || venueSlug == "undefined") venueSlug = venue.id;
                venueprivateroomsContainer.append(`
                <div class="results-wrapper">
                    <div class="yelpresults-content yelpresults-container${ index}">
                    <div class="item-image-container">
                        <a href="/restaurant/${ venueSlug}" class="orange">
                            <img id="${ venue.venueId}" img-type="Restaurant"  class="itemImage" src="/images/tempfoodnotext.png">
                        </a>

                    </div>
                    <div class="item-details mt-2">
                        <div class="item-detail-column">
                            <h3>
                                <a href="/restaurant/${venueSlug}" id="venue-name-pr" class="orange">
                                    ${ venue.name}
                                </a>
                            </h3>
                            <div class="yelp-rating-stars-pr"></div>
                        </div>
                        <p class="venue-address" name="venue-address"> ${ venue.location.formattedAddress.join(', ') }  </p>
                        <a href="tel: +1 ${ venue.contact ? venue.contact.formattedPhone || '' : ''}" name="venue-phone"
                            class="orange venue-phone"> ${ venue.contact ? venue.contact.formattedPhone || '' : ''}
                        </a>

                        <div class="venue-distance"><label><span class="green">${ venue.location.distance} mi</span> away</label>  </div>
                        <a href="/restaurant/${ venueSlug}" class="btn btn-default view-venue-btn">
                            View this Restaurant
                        </a>
                    </div>
                    <div class="hidden venueData">
                        <input id="venue-phone-pr" type="hidden" value="${ venue.contact ? venue.contact.phone || '' : ''}">
                        <input id="venue-id-pr" type="hidden" value="${ venue.venueId}">
                        <input id="venue-location-pr" type="hidden" value="${ venue.location.formattedAddress.join(', ')}">
                        <input id="venue-distance-pr" type="hidden" value="${ venue.location.distance}">
                        <input id="venue-lat-pr" type="hidden" value="${ venue.location.lat}">
                        <input id="venue-lng-pr" type="hidden" value="${ venue.location.lng}">
                    </div>
                </div>
                ${index + 1 == venues.length ? '' : '<hr class="line-divider"/>' }
                `
                )
            });
        }))
        .then(() => {
            // Object to hold impressions data
            let homePageImpressionsData = {
                userId: $('#account-id').val(),
                venues: []
            };
            $('.yelpresults-content-private-room').each(function (index, element) {
                // Pushing venueId values to venues array for impressions data
                homePageImpressionsData.venues.push($(this).find('#venue-id-pr').val());
                let parentContainer = ".yelpresults-container" + index;
                let venueParams = {
                    name: $(this).find('#venue-name-pr').text().trim(),
                    location: $(this).find('#venue-location-pr').val().trim(),
                    lat: $(this).find('#venue-lat-pr').val().trim(),
                    lng: $(this).find('#venue-lng-pr').val().trim(),
                    phone: $(this).find('#venue-phone-pr').val().trim()
                };
                let $yelpRatingContainer = $(parentContainer).find('.yelp-rating-stars-pr')

                //Get yelp ratings
                if ($yelpRatingContainer) {
                    $($yelpRatingContainer).getYelpRatings({
                        requestParams: venueParams
                    })
                }
                if (index === $('.yelpresults-content').length - 1) {
                    $.promisedAjax({
                        url: '/analytics/homepageimpressions',
                        type: 'POST',
                        data: JSON.stringify(homePageImpressionsData),
                        contentType: 'application/json'
                    }).catch((err) => console.error(err))
                }
            });
        })
    })
// } //end of function for displaying carousel
$('.item-detail-column, .view-venue-btn').on('click', function(){
    if($.xhrPool && typeof $.xhrPool  !== undefined ) $.xhrPool.abortAll();
})

/* commenting below since browser detection of lat, lng not being used
    let geoLocation = (function () {
        let latitudeInput = document.getElementById("lat");
        let longitudeInput = document.getElementById("lng");
        let latitudeRoom = document.getElementById("room_lat");
        let longitudeRoom = document.getElementById("room_long");
        let headerlatitudeInput = document.getElementById("headersearch-lat");
        let headerlongitudeInput = document.getElementById("headersearch-lng");
        let popuplocationinput = document.getElementById("headersearch-locationinputsearch");

        let advancelatitudeInput = document.getElementById("advance-lat");
        let advancelongitudeInput = document.getElementById("advance-lng");
        let advancelocationinput = document.getElementById("advance-locationinputsearch");



        let locationinput = document.getElementById("head-location-input-search");
        let $locationContainer = $('.head-inner-location-container');
        let watchID;
        let savedLat = Cookies.get('posLat');
        let savedLon = Cookies.get('posLon');

        //if lat/lng exists, do not geocode it again; below was setting lat/long back to the currently detected locations
        let tlat = $("#lat").val();
        let tlng = $("#lng").val();
        let tlocation = $("#head-location-input-search").val();
        if((tlat && tlng) && tlocation){
          return false;
        }

        let _updatePositions = function(position) {
          let newLat = position.coords.latitude;
          let newLon = position.coords.longitude;
            commonupdatePositions(position);

            $('#headersearch-lat').val(newLat);
            $('#headersearch-lng').val(newLon);

            $('#headersearch-lat').trigger('change');

            $('#advance-lat').val(newLat);
            $('#advance-lng').val(newLon);
            $('#advance-lat').trigger('change');

            if(!position.fromIp){
                _updateLocationPlaceholder("detected");
                $(".location-error").remove();
            } else {
                $('#approximate').val(position.fromIp);
                $('#room_approximate').val(position.fromIp);
            }
        };

        let _errorOnLocation = function(e) {
         // console.log("in _errorOnLocation..",e);
            //detect location from ip
            if(!e || (e && e.code == 1)){
                ipLookUp("permissionDenied","#showlocationmessage-container",_updateLocationPlaceholder,_updatePositions);
            } else {
              ipLookUp("locationNotAvailable","#showlocationmessage-container",_updateLocationPlaceholder,_updatePositions);

            }
            _updateLocationPlaceholder("error");
        };

        let _geoFindMe = function() {
            if (!navigator.geolocation) {
                locationinput.placeholder = "Location detection is disabled in your device";
                popuplocationinput.placeholder = "Location detection is disabled in your device";
                return;
            }
           //locationinput.value = " ";
            _updateLocationPlaceholder("loading");
            //watchID = navigator.geolocation.watchPosition(_updatePositions, _errorOnLocation)
            commonGetLocation(_updatePositions, _errorOnLocation);
       };

        let _updateLocationPlaceholder = function(locationStatus, newInput) {
          let inputId ="locationinputheader" //holds current typed location in case location loads location after user begins to type
          _updateLocationCommonPlaceholder(locationStatus,$locationContainer,inputId);
        };

        let init = function() {
           _updateLocationPlaceholder('loading');
            let savedLat = Cookies.get('posLat');
            let savedLon = Cookies.get('posLon');
            if(!check_iOSapp() && !check_androidApp()){
                if(savedLat==null){
                    if (navigator.permissions && navigator.geolocation) {
                        navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                                if(result.state === "prompt") {
                                    _geoFindMe();
                                    Cookies.set('locationPermission', 'prompt')
                                } else if (result.state === "granted") {
                                _geoFindMe();
                                    Cookies.set('locationPermission', 'granted');
                                } else if (result.state === "denied") {
                                    ipLookUp("permissionDenied","#showlocationmessage-container",_updateLocationPlaceholder,_updatePositions);
                                }
                            });
                        } else {
                           // console.log('navigator Geolocation or permission not found')
                            //Certain browsers, like Safari, do not support navigator permission so let's
                            //Just start geoLocation detection without showing alerts.
                            _geoFindMe();
                        }

                }
                else{
                    _geoFindMe();
                    Cookies.set('locationPermission', 'granted');
                }
            }
        };

        return {
            init: init,
            updateLocationPlaceholder:_updateLocationPlaceholder,
            ipLookUp: ipLookUp,
            updatePositions: _updatePositions
        }
    })();
    if(typeof geoLocation.init === "function") geoLocation.init();
    window.geoLocation = geoLocation;

//})();
*/

        $('.vprops2 .item').hover( function() {
                $(this).toggleClass('flipped')
            }, function(){
                $(this).removeClass('flipped');
        });
        $('.vprops2 .item').on('click', function() {
            $(this).toggleClass('flipped');
        });



function displayReviews(el){
	get_reviews($(el));
}

//create and set the categories and subcategories names and ids to a form variable
    function setAllSelectedCategories(){
        //set the selected category ids and names incl subcategories names and ids
        let selectedmenutypes = $("#menuTypePreferences").val();
        if(selectedmenutypes && selectedmenutypes.length>0 && typeof menuTypesArr !=="undefined" && menuTypesArr.length > 0){
          let arrids=[];
          let arrnames=[];
          selectedmenutypes.forEach((name) => {
            let filteredarr = menuTypesArr.filter(x=>x.text == name);
            if(filteredarr && filteredarr.length>0) {
              for(let i=0; i<filteredarr.length;i++){
                arrids.push(filteredarr[i].fsqrid);
                arrnames.push(filteredarr[i].text);
                //check for subcat with the fsqid as parentid
                let filteredparentid=filteredarr[i].fsqrid;
                if(filteredparentid){
                  let filteredarrp = menuTypesArr.filter(x=>x.parentid == filteredparentid);
                  if(filteredarrp && filteredarrp.length>0) {
                    for(let j=0; j<filteredarrp.length;j++){
                      arrids.push(filteredarrp[j].fsqrid);
                      arrnames.push(filteredarrp[j].text);
                      //check for subsubcat with the fsqrid as parentid
                      let filteredgparentid=filteredarrp[j].fsqrid;
                      if(filteredgparentid){
                        let filteredarrgp = menuTypesArr.filter(x=>x.parentid == filteredgparentid);
                        if(filteredarrgp && filteredarrgp.length>0) {
                          for(let k=0; k<filteredarrgp.length;k++){
                            arrids.push(filteredarrgp[k].fsqrid);
                            arrnames.push(filteredarrgp[k].text);
                          } //end foreach filteredarrgp
                        } //if filteredarrgp
                      } //if filteredgparentid
                    } //end foreach filteredarrp
                  } //if filteredarrp
                } //if filteredparentid
              } //end foreach filteredarr
            } //if filteredarr
          });
          //set the selected ids comma separated into a post variable
          $("#all_categoryids").val(arrids.join(","));
          $("#all_categorynames").val(arrnames.join(","));
         }
    }

	function getMatch(a,b){
		let value = b.diff(a);
		return value;
	}

	Array.prototype.diff = function(a) {
     return this.filter(function(i) {return a.indexOf(i) < 0;});
  };


function flip_html(nid){
  $('#'+nid+'').parents('.card').addClass('flipped').mouseleave(function() {
      $(this).removeClass('flipped');
  });
  return true;
}
function likeFunc(el){
  let menuItemId=el.getAttribute("data-item-id")
  let venueId=el.getAttribute("data-venueid")
  let userId=el.getAttribute("data-user-id")
  if(userId !="undefined"){
 let  data={
    venueId: venueId,
    userId:userId,
  }
    if(menuItemId){
      data["menuItemId"]=menuItemId
    }
  $.ajax({
    url: '/users/menuItemDislike',
    method: 'POST',
    dataType: 'json',
    data:  data,
    success: function(data) {
      if(data){
        try {
          if(el.classList.contains("likewishlist-far"))
          {
            el.classList.remove("far")
            el.classList.remove("likewishlist-far")
            el.classList.add("fas");
            el.classList.add("likewishlist-fas");
          }
          else {
            el.classList.remove("fas")
            el.classList.remove("likewishlist-fas")
            el.classList.add("far")
            el.classList.add("likewishlist-far")
          }
        } catch (error) {
          console.log("ajax dislike error",error)
        }
    }
  }
});
  }
  else {
        // window.location.href = '/users/login'
        let href=window.location.href;
        href="/users/login?next="+href.split("?")[1]
        let temp=window.location.href.split('/');
       let out=[]
      for(let i=0; i<temp.length;i=i+3)
      out.push(temp.slice(i,i+3).join('/'));
      window.location.href="/users/login?next="+out[1]
      }

}
//primaryType is what is pased from controller with max score of es search
//if value is privateroom or default, then only tab is clicked before search
//if value is menuitem and restaurant, then tab is clicked AFTER search results and serahc results are to be displayed


    //add to order btn on search result page and carousel page
   function addItemToCart(venueId, menuItemId, venueSlug){
     if(menuItemId && venueId) {
     //orders.js->getMenuSelectedItems reads selectedStorage, so setting that
      let items1 = localStorage.getItem('selectedItems');
        if(!items1) {
          items1 = [];
        } else {
          items1 = JSON.parse(items1);
        }

      localStorage.page_log = '';
      items1.push(menuItemId) ;
//you can only store strings in localStorage
      localStorage.setItem('selectedItems', JSON.stringify(items1));
      let orderedBeforeLogin = localStorage.getItem('orderedBeforeLogin');
      let userLoggedIn = $('#userId').val();
 //we are using buymenuitemid, so not setting this       if (typeof(Storage) !== "undefined") sessionStorage.action = "buy-btn";
      if(localStorage && (typeof userLoggedIn == "undefined" || !userLoggedIn) ){
        localStorage.url_before_logging_in = `/restaurant/${venueSlug ? venueSlug : venueId}`;
        localStorage.orderedBeforeLogin = true;
         window.location.assign(`/users/register?next=/restaurant/${venueSlug ? venueSlug : venueId}&buymenuitemid=${menuItemId}`);
       } else {
         window.location.assign(`/restaurant/${venueSlug ? venueSlug : venueId}?buymenuitemid=${menuItemId}`);

     }
   }
}
//reset popup filters values
$("#reset_header_filter").on('click',function(){
  $(".header-filter-orderType").find('.order-type-li').removeClass('active');
  $("#dietaryPreferencesHeader").select2();
  let selectedval = $('#dietaryPreferencesHeader').val();
  let getuserdata = userdietnames;
  let user_id = $('#userId').val();
  if(user_id) {
    $("#dietaryPreferencesHeader").select2();
    $("#dietaryPreferencesHeader").val(null).trigger("change");
    let concatdata = selectedval.concat(getuserdata);
    let uniquedata = $.unique(concatdata);
    let result = Array.from(new Set(uniquedata));
    addUserPrefs(getuserdata,"dietaryPreferencesHeader");
  }
  else{
    $("#dietaryPreferencesHeader").select2();
    $("#dietaryPreferencesHeader").val(null).trigger("change");
  }
  $("#headersearch-locationinputsearch").val($("#inputSwitchSpan").text());
  $("#header_search_radius_input").val('');
  $("#header_search_location").val('');

  $("#header_filter_orderTypePickup").prop("checked", true);
  $("#header_filter_orderTypeDelivery").prop("checked", true);
  $("#header_filter_orderTypeOrderAhead").prop("checked", true);
  $("#header_other_rated").prop("checked", false);
  $("#header_i_rated").prop("checked", false);
  $("#header_meets_foodmatch").prop("checked", false);
  $("#header_highest_flavour_match").prop("checked", false);
  let mySlider = $("input#price_search_header").bootstrapSlider();
  mySlider.bootstrapSlider("setValue", [0, 100]);
  $(" #priceRange_header .min-slider-handle").html("<p  style='position: absolute;top: 20px;width: 60px;' class='low'>$  0 </p>");
  $("#priceRange_header .max-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$ 100</p>");
  flavorMatchfun(80);

  $("#headersearch-radius-value-text").text(30);

  let radius = Number($("#header_search_radius_input").val()||0);
  radius = setRadius(radius - 50, 50, 30, 100)
  $RADIUS_VALUE_TEXT.val(radius);
  $RADIUS_VALUE.val(radius);
  $RADIUS_PRIVATE_VALUE.val(radius)

 /* $("#priceRange_header .min-slider-handle").html("");
  $("#priceRange_header .max-slider-handle").html("");*/
  $("#checkboxClosestHeader").prop("checked", true);
  $("#checkboxRatedHeader").prop("checked", true);
  $("#checkboxDiscountHeader").prop("checked", true);
  $("#checkboxFlavorHeader").prop("checked", true);
  $("#checkboxValueHeader").prop("checked", true);
  $("#checkboxDeliveryHeader").prop("checked", false);
  $("#checkboxMinimumOrderHeader").prop("checked", false);
  $("#checkboxLowCostHeader").prop("checked", false);
  $("#filter_reviews-header").prop("checked", false);
  if($("#show-food-not-meet-header").val()=='Y'){
    $("#show-food-not-meet-header").prop("checked", true);
  }else{
    $("#show-food-not-meet-header").prop("checked", false);
  }

})

$("#price_search_header").change(function(){
  let priceVal = $('#price_search_header').val();
  let arr = priceVal.split(',');
  if(arr[0]==100){
    $(" #priceRange_header .min-slider-handle").html("<p  style='position: absolute;top: 20px;width: 60px;' class='low'>$ " +arr[0] + '</p>');
  }
  else{
    $("#priceRange_header .min-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$ " +arr[0] + '</p>');
  }
  if(arr[1]==100){
    $("#priceRange_header .max-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$ " +arr[1] + '</p>');
  }
  else{
    $("#priceRange_header .max-slider-handle").html("<p style='position: absolute;top: 20px;width: 60px;' class='low'>$ " +arr[1] + '</p>');
  }
});


$("#header_search_radius_inputbox").on('input', function(){
    let radius = Number($(this).val()||0);
    if (Number(radius) < 25) {
        radius = setRadius(radius, 25, 0.1, 0.5)
    } else if (Number(radius) < 50) {
        radius = setRadius(radius - 25, 25, 0.5, 5)
    } else {
        radius = setRadius(radius - 50, 50, 5, 100)
    }
  $("#headersearch-radius-value-text").text(radius);
  $("#header_search_radius_input").val(radius);
});

//functions to handle adding order types to url when they are clicked, so that refreshing the page without search, still has those values
//function to remove query params from a URL
function removeURLParameter(url, parameter) {
    //better to use l.search if you have a location/link object
    let urlparts= url.split('?');
    if (urlparts.length>=2) {

        let prefix= encodeURIComponent(parameter)+'=';
        let pars= urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (let i= pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url= urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
        return url;
    } else {
        return url;
    }
}

//function to add/update query params
function insertParam(key, value) {
    if (history.pushState) {
        // let newurl = window.location.protocol + "//" + window.location.host + search.pathname + '?myNewUrlQuery=1';
        let currentUrlWithOutHash = window.location.origin + window.location.pathname + window.location.search;
        let hash = window.location.hash
        //remove any param for the same key
        currentUrlWithOutHash = removeURLParameter(currentUrlWithOutHash, key);

        //figure out if we need to add the param with a ? or a &
        let queryStart;
        if(currentUrlWithOutHash.indexOf('?') !== -1){
            queryStart = '&';
        } else {
            queryStart = '?';
        }

        let newurl = currentUrlWithOutHash + queryStart + key + '=' + value + hash
        console.log("returning url..after ordertype select..", newurl)
        window.history.pushState({path:newurl},'',newurl);
//why this        commonhomepage();
    }
}
// $("#home-page-search,#headsearchhome").click(function(){
//  commonhomepage();
// });

$(document).on('click', '#home-page-search, #headsearchhome', function () {
  commonhomepage();
})

function commonhomepage(){
  let locationinput = $("#hide-locationinput").html();
  console.log("in commonhomepage...locationinput=", locationinput);
  if(!locationinput) locationinput="Enter Your Location";
  $.ajax({
      url: "/homepage",
      data: {orderTypeDelivery:orderTypeDelivery,locationinput:locationinput,orderTypeOrderAhead: orderTypeOrderAhead,orderTypePickup:orderTypePickup},
      type: 'post',
      dataType:'json',
      error: function(e){
//        console.log("in commonhomepage ajax error...", JSON.stringify(e));
        window.location.href="/homepage";
      },
      success: function(results){
        console.log("commonhomepage ajax results====",results)
        window.location.href="/homepage";
      }

    });
}
$('#headersearchnavpill .nav-link').on('click', function(){
	if($(this).attr('data-key') == "advancedsearch"){
		window.location.href="/advancedSearch";
	}else if($(this).attr('data-key') == "restaurants"){
			$(".home-customers-content").css('display', 'none');
			$(".home-resturants-content").css('display', 'block');
	}else if($(this).attr('data-key') == "customers"){
		$(".home-customers-content").css('display', 'block');
			$(".home-resturants-content").css('display', 'none');
	}
})
